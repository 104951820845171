import React from "react";
import { StyledButton } from "./StyledButton";

interface IButton {
  disabled?: boolean;
  children: React.ReactNode;
  className?: string;
  onClick: (...args: any) => void;
  role?: "primary" | "secondary" | "tertiary";
  id?: string;
  startDecorator?: React.ReactNode;
  endDecorator?: React.ReactNode;
}

const Button = ({
  disabled = false,
  children,
  className,
  onClick,
  role = "primary",
  id,
  startDecorator,
  endDecorator,
}: IButton) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onClick(e);
  };

  return (
    <StyledButton
      $disabled={disabled}
      $btnrole={role}
      className={className}
      onClick={handleClick}
      id={id}
    >
      {startDecorator}
      {children}
      {endDecorator}
    </StyledButton>
  );
};

export default Button;
