import styled from "styled-components";

export const StyledFilters = styled.div`
  margin-bottom: 32px;
  position: sticky;
  z-index: 100;
  left: 0;

  select[multiple] {
    option:checked {
      background-color: #ffbb00a8;
      color: #000;
    }
  }

  .main-section {
    display: grid;
    grid-template-columns: 310px 220px 100px;

    .search-bar {
      width: 300px;
      height: 100%;
    }

    .filters-switch-wrapper {
      width: 100%;

      button {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        font-family: inherit;
        font-weight: 700;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none;
        border: 1.5px solid #e0e0e0;
        cursor: pointer;
        padding-inline: 10px;

        img {
          margin-right: 8px;
          width: 18px;
          height: 18px;
        }

        .close-filters-img {
          width: 14px;
          height: 14px;
          filter: brightness(0);
          margin-left: auto;
        }
      }
    }

    .search-btn {
      margin-left: 0.5rem;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      font-family: inherit;
      font-weight: 700;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      border: 1.5px solid var(--blue);
      background-color: var(--blue);
      color: white;
      cursor: pointer;
      padding-inline: 10px;
    }
  }

  .filters-section {
    margin-top: 16px;
    border-radius: 8px;
    transition: all 0.2s;
    overflow: hidden;
    display: flex;
    grid-gap: 8px;
    align-items: flex-end;
    flex-wrap: wrap;

    &__opened {
      height: 100%;
      max-height: 1000px;
      padding: 16px;
      border: 1.5px solid #e0e0e0;
      background-color: #ffffff;
      opacity: 1;
    }

    &__closed {
      height: 0;
      max-height: 0;
      padding: 0;
      border: 1.5px solid transparent;
      background-color: transparent;
      opacity: 0;
    }

    .label {
      margin: 4px;
      font-size: 12px;
      padding-right: 4px;
    }

    select {
      border: 1.5px solid #e0e0e0;
      outline: none;
      padding: 8px 12px;
      font-family: inherit;
      font-weight: 800;
      border-radius: 8px;
      cursor: pointer;
      width: 100%;
    }

    .add-filter-btn {
      button {
        width: fit-content;
        border: 1.8px solid black;
        border-radius: 8px;
        background-color: transparent;
        outline: none;
        padding: 8px 12px;
        font-family: inherit;
        font-weight: 800;
        cursor: pointer;
      }
    }
  }
`;
