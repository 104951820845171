import { createAsyncThunk } from "@reduxjs/toolkit";
import { IWorker } from "../../types/filterFields";
import { client } from "../../lib/axios";

export const getKanbanWorkers = createAsyncThunk(
  "kanban/getKanbanWorkers",
  async ({ lastDays }: { lastDays: number }): Promise<{ rows: IWorker[] }> => {
    const response = await client.get("/kanban/workers/latest", {
      params: { lastDays },
    });

    return response.data as { count: number; rows: IWorker[] };
  }
);

export const updateKanbanBoard = createAsyncThunk(
  "kanban/updateKanbanBoard",
  async ({
    workerId,
    board,
  }: {
    workerId: string;
    board: string;
  }): Promise<{
    success: boolean;
    updated: { codice_fiscale: string; board: number };
  }> => {
    const response = await client.patch("/kanban/workers", {
      codiceFiscale: workerId,
      board,
    });

    return response.data as {
      success: boolean;
      updated: { codice_fiscale: string; board: number };
    };
  }
);
