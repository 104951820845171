import React, { useEffect } from "react";
import { StyledVerificationEmail } from "./StyledVerificationEmail";
import {
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { app } from "../../lib/firebase/config";
import Button from "../vetrina/components/Button";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../store/slices/main";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";

const VerificationEmail = () => {
  const auth = getAuth(app);
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const dr = useDispatch<any>();

  const sendVerificationEmail = async () => {
    dr(setIsLoading(true));
    await auth.signOut();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      if (!auth.currentUser) {
        alert("Errore, riprova");
        return;
      }
      let url = "https://cercalatuacolf.assindatcolf.it";
      if (process.env.REACT_APP_PROJECT_ID === "assindatcolf-recruit-dwh-dev") {
        url = "http://localhost:3000";
      }
      if (
        process.env.REACT_APP_PROJECT_ID === "assindatcolf-recruit-dwh-stage"
      ) {
        url = "https://assindatcolf-recruit-dwh-stage.web.app";
      }
      const db = getFirestore(app);
      const docRef = collection(db, "internal_users");
      const q = query(docRef, where("email", "==", email));
      const querySnapshot = (await getDocs(q)) as any;
      await sendEmailVerification(auth.currentUser, {
        url:
          url +
          "/r/impl-2fa?cf=" +
          encodeURIComponent(querySnapshot.docs[0].data().codice_fiscale),
      });
      alert("Email di verifica inviata");
    } catch (e) {
      alert(e);
    }
    dr(setIsLoading(false));
  };

  return (
    <StyledVerificationEmail>
      <div className="form-wrapper">
        <h3>Invia Email di Verifica</h3>
        <p>
          Attenzione, questo ti slogghera' dalle altre sessioni, esegui l'invio
          in modalita' incognito per salvare la tua sessione corrente
        </p>
        <input
          type="text"
          placeholder="Email"
          className="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          className="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button onClick={sendVerificationEmail}>Invia email</Button>
      </div>
    </StyledVerificationEmail>
  );
};

export default VerificationEmail;
