import React, { useState } from "react";
import Button from "../../components/Button/index";
import { IconInterfacesLock } from "../../components/IconInterfacesLock";
import { TypeForm } from "../../components/TypeForm/index";
import "./style.css";
import Logo from "../../components/Logo/Logo";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { isCodiceFiscale, stingIsEmpty } from "../../utils/utils";
import ErrorMessageInForm from "../../components/ErrorMessageInForm/index";
import { doc, getDoc } from "firebase/firestore";
import db from "../../index";
import { useDispatch } from "react-redux";
import { setIsLoading, setMultifactorError } from "../../store/slices/main";
import { saveSession } from "../../utils/auth/session";
import { client } from "../../lib/axios";

export const SignInPage = () => {
  const [codiceFiscale, setCodiceFiscale] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const auth = getAuth();

  const dr = useDispatch();

  const handleClickLoginButton = async () => {
    dr(setIsLoading(true));

    try {
      // const result = await checkAssociato(codiceFiscale);
      // console.log(result);

      //setErrorMessage("Ciao");
      // Perform additional actions here
      if (isCodiceFiscale(codiceFiscale)) {
        if (stingIsEmpty(password)) {
          setErrorMessage("Inserisci la password");
          //console.log("è un codice fiscale valido")
        } else {
          //GET MAIL
          const docRef = doc(db, "external_users", codiceFiscale);
          const docSnap = await getDoc(docRef);
          let email = "";
          let multi_factor_auth = false;

          if (docSnap.exists()) {
            //console.log("Document data:",  docSnap.data()["email"]);
            email = docSnap.data()["email"]?.toLowerCase();
            multi_factor_auth = docSnap.data()["multi_factor_auth"];

            await saveSession({ codiceFiscale });

            //LOGIN
            signInWithEmailAndPassword(auth, email, password)
              .then(async (userCredential) => {
                if (!multi_factor_auth) {
                  navigate(`/r/impl-2fa?cf=${codiceFiscale}`, {
                    replace: false,
                  });
                }

                // Signed in
                const result = await client.post("/auth/check-associato", {
                  body: {
                    codiceFiscale,
                  },
                });

                if (!result.data.active) {
                  setErrorMessage(
                    "Il servizio di ricerca e selezione è riservato ai soli associati."
                  );
                }
              })
              .catch((error) => {
                if (
                  error.message.includes("(auth/multi-factor-auth-required)")
                ) {
                  dr(setMultifactorError(error));
                  const searchParams = new URLSearchParams(
                    window.location.search
                  );

                  const from = searchParams.get("from") || "/vetrina";

                  navigate(
                    `/r/impl-2fa?cf=${codiceFiscale}&code=true&from=${from}`,
                    {
                      replace: false,
                    }
                  );
                }
                let errorMessage =
                  "Si è verificato un errore, perfavore riprova.";
                if (error.message.includes("invalid-login-credentials")) {
                  errorMessage = "Credeziali errate, perfavore riprova.";
                }
                setErrorMessage(errorMessage);
                console.log(error);
              });
          } else {
            // docSnap.data() will be undefined in this case

            setErrorMessage("Utente non risulta registrato");
          }
        }
      } else {
        //console.log("NON è un codice fiscale valido")
        setErrorMessage("Inserisci un codice fiscale valido");
      }
    } catch (error) {
      console.log(error);
    } finally {
      dr(setIsLoading(false));
    }
  };

  const forgetPassword = (value) => {
    navigate("/password-dimenticata", { replace: true }); // Move to Home page
  };

  const handleCodiceFiscaleChange = (value) => {
    // Utilizza il valore nel componente genitore
    //console.log("stampo la mail",value);
    setCodiceFiscale(value.toUpperCase());
  };

  const handleTypePasswordChange = (value) => {
    // Utilizza il valore nel componente genitore
    //console.log("stampo la password",value);
    setPassword(value);
  };

  return (
    <div className="log-in-interno">
      <Logo></Logo>
      <div className="login-screen">
        <div className="typeforms-buttons">
          <div className="title-container">
            <h1 className="title">Log In</h1>
          </div>
          <div className="div">
            <div className="div">
              <TypeForm
                className="type-form-instance"
                label="Codice Fiscale"
                value={codiceFiscale}
                onChange={handleCodiceFiscaleChange}
              />
              <TypeForm
                className="type-form-instance"
                label="Password"
                isPassword={true}
                onChange={handleTypePasswordChange}
                value={password}
                override={
                  <IconInterfacesLock className="design-component-instance-node" />
                }
              />
            </div>
            <div className="other-typeform">
              {/*
              <div className="ricordami-check">
                <Checkbox className="design-component-instance-node" property1="checked" />
                <div className="text-wrapper">Ricordami</div>
              </div>*/}
              <div className="text-wrapper-2" onClick={forgetPassword}>
                Hai dimenticato la password?
              </div>
            </div>
          </div>
          <>
            <ErrorMessageInForm message={errorMessage} />
          </>
          <div className="buttons">
            <Button
              className="button-instance"
              property1="primary"
              text="Accedi"
              onClick={handleClickLoginButton}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
