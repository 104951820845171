// File: /src/lib/axios.ts
// Created Date: Monday September 16th 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Monday September 16th 2024 5:57:24 pm
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import axios from "axios";

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
