import React, { useState } from "react";
import Button from "../../../components/Button/index";
import "../style.css";
import Logo from "../../../components/Logo/Logo";
import ErrorMessageInForm from "../../../components/ErrorMessageInForm";
import SelectChoice from "../../../components/SelectChoice/SelectChoise";
import { stepSevenCarAvailability } from "../stepsVariables";
import { isAcceptableStepOnlyOne } from "../functionsSteps";
import { isStepValid } from "../../../utils/onboarding";
import AvantiIndietroStepButtons from "../../../components/AvantiIndietroStepButtons";

const CarAvailability = ({
  onNext,
  formData,
  onPrevious,
  onInputChange,
  title,
  subtitle,
}) => {
  const { carAvailability } = formData;
  const [errorMessage, setErrorMessage] = useState("");

  const handleClickNextButton = () => {
    //console.log(acceptableConditions);
    //console.log(carAvailability);
    if (isAcceptableStepOnlyOne(carAvailability)) {
      onNext();
      console.log(formData);
    } else {
      setErrorMessage("Inserisci una risposta in questa domanda");
    }
  };

  const handleSelectChoiceClick = (isClicked, text) => {
    //console.log("isClicked:", isClicked);
    //console.log("text:", text);

    // Copia l'array carAvailability in updatedCarAvailability
    // const updatedCarAvailability = [text];

    //console.log(updatedCarAvailability);

    // if (isClicked) {
    //   // Aggiungiamo l'elemento text alla lista se isClicked è true e l'elemento non è già presente
    //   if (!updatedCarAvailability.includes(text)) {
    //     updatedCarAvailability.push(text);
    //   }
    // } else {
    //   // Rimuoviamo l'elemento text dalla lista se isClicked è false e l'elemento è presente
    //   const index = updatedCarAvailability.indexOf(text);
    //   if (index !== -1) {
    //     updatedCarAvailability.splice(index, 1);
    //   }
    // }

    // Aggiorna carAvailability con l'array modificato
    console.log(carAvailability);
    onInputChange("carAvailability", [text]);
  };

  const handleClickPreviousButton = () => {
    // onInputChange("drivingLicense", []);
    // onInputChange("carAvailability", []);

    onPrevious();
  };

  return (
    <div className="onboarding-interno">
      <Logo></Logo>
      <div className="onboarding-screen">
        <div className="typeforms-buttons">
          <div className="title-container">
            <h1 className="title">{title}</h1>
          </div>

          <h2 className="subtitle">{subtitle}</h2>

          <div className="div">
            <div className="selection-element-horrizonatal">
              {stepSevenCarAvailability.map((item) => (
                <SelectChoice
                  key={item}
                  text={item}
                  onClick={handleSelectChoiceClick}
                  isClickedProp={carAvailability.includes(item)}
                />
              ))}
            </div>
            <ErrorMessageInForm message={errorMessage} />
            <AvantiIndietroStepButtons
              onNext={handleClickNextButton}
              onPrevious={handleClickPreviousButton}
              nextDisabled={!isAcceptableStepOnlyOne(carAvailability)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarAvailability;
