// File: /src/components/AvantiIndietroStepButtons/styles.ts
// Created Date: Tuesday September 3rd 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Tuesday September 3rd 2024 3:14:27 pm
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import styled from "styled-components";

export const StyledAvantiIndietroStepButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;

  & > * {
    flex: 1;
  }
`;
