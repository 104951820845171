import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import Menu from "../../components/Menu/index.js";
import { mockDataFamilies } from "./mockData";
import Table from "../../components/Table/Table";
import Filters from "./model-table/components/Filters/Filters";
import {
  resetFilters,
  setData,
  setPage,
  setPageTo,
} from "../../store/slices/databaseTable";
import { getFilterFields, getFieldKeys } from "../../utils/jsonUtils";
import {
  setAvailableFilters,
  setFieldKeys,
} from "../../store/slices/databaseTable";
import Editor from "./model-table/components/Editor";
import { functions } from "../../lib/firebase/functions";
import { httpsCallable } from "firebase/functions";
import { getColumnModelFamilies } from "./model-table/familiesModelTable";
import { getFamilies } from "../../store/thunks/databaseTable";

import chevronRight from "../../assets/chevron-right.png";
import chevronLeft from "../../assets/chevron-left.png";
import { app } from "../../lib/firebase/config";
import {
  registerUserToSession,
  setSelection,
} from "../../store/slices/vetrina";
import { UserTypes } from "../../types/auth";

// Custom component to render Genres
const Genres = ({ values }) => {
  // Loop through the array and create a badge-like component instead of a comma-separated string
  return (
    <>
      {values.map((genre, idx) => {
        return (
          <span key={idx} className="badge">
            {genre}
          </span>
        );
      })}
    </>
  );
};

const DatabasePageFamilies = () => {
  const [userType, setUserType] = useState();
  const [isAuth, setIsAuth] = useState(false);
  const auth = getAuth();
  const dispatchRedux = useDispatch();
  const data = useSelector((state) => state.databaseTable.data);
  const navigate = useNavigate();
  const [columnsModelFamilies, setColumnsModelFamilies] = useState([]);
  const [order, setOrder] = useState("desc");

  const currentPage = useSelector((state) => state.databaseTable.currentPage);
  const count = useSelector((state) => state.databaseTable.count);
  const filteredData = useSelector((state) => state.databaseTable.filteredData);
  const isSearching = useSelector((state) => state.databaseTable.isSearching);
  const [orderField, setOrderField] = useState("creation_time");

  const primaElemento = (currentPage - 1) * 50 + 1;
  const ultimoElemento =
    count > 50 ? primaElemento + filteredData.length - 1 : count;

  useEffect(() => {
    dispatchRedux(resetFilters());
  }, []);

  useEffect(() => {
    const auth = getAuth(app);
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        setIsAuth(true);
        const db = getFirestore(app);
        const docRef = collection(db, "internal_users");
        const q = query(docRef, where("email", "==", user.email));
        const querySnapshot = await getDocs(q);
        setUserType(querySnapshot.docs[0]?.data()?.user_type);
        if (
          querySnapshot.docs?.length === 0 ||
          querySnapshot.docs[0].data().user_type !== UserTypes.ADMIN
        ) {
          navigate(
            `/sign-in-admin?from=${encodeURIComponent(
              window.location.pathname
            )}`
          );
        } else {
          dispatchRedux(registerUserToSession(querySnapshot.docs[0].data()));
          dispatchRedux(
            setSelection(querySnapshot.docs[0].data().selection ?? [])
          );
        }
      } else {
        navigate(
          `/sign-in-admin?from=${encodeURIComponent(window.location.pathname)}`
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchColumns = async () => {
      const columns = await getColumnModelFamilies();
      setColumnsModelFamilies(columns);
    };
    fetchColumns();
  }, []);

  useEffect(() => {
    getFilterFields("families").then((res) =>
      dispatchRedux(setAvailableFilters(res))
    );
    getFieldKeys("families").then((res) => dispatchRedux(setFieldKeys(res)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatchRedux(getFamilies({ currentPage, order, orderField }));

    // getFamilies().then((res) => {
    //   dispatchRedux(setData(res.data));
    // });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, order, orderField]);

  const handlePagination = (direction) => {
    if (direction === "left") {
      dispatchRedux(setPage(direction));
    } else if (direction === "right") {
      dispatchRedux(setPage(direction));
    }
  };

  if (isAuth) {
    return (
      <>
        <div className="container">
          <Menu initialItemSelected="Database"></Menu>
          <div className="content">
            <div className="table-admin-button-container">
              <button className="table-admin-flex-button isSelected">
                💼 Datori di lavoro
              </button>
              <button
                className="table-admin-flex-button isNotSelected"
                onClick={() => {
                  dispatchRedux(setPageTo(1));
                  navigate("/database-lavoratori", { replace: true });
                }}
              >
                🙋‍♀️ Lavoratori
              </button>
            </div>
            <Filters />
            <div className="wrapper-table">
              <Table
                columns={columnsModelFamilies}
                data={data}
                orderField={orderField}
                setOrderField={setOrderField}
                setOrder={setOrder}
              />
            </div>
            {!isSearching ? (
              <div className="count-wrapper">
                <img
                  className={`${primaElemento === 1 ? "disabled" : ""}`}
                  onClick={() => handlePagination("left")}
                  src={chevronLeft}
                  alt=""
                />
                <p>
                  {primaElemento} - {ultimoElemento}
                </p>
                <p>/</p>
                <p>{count}</p>
                <img
                  className={`${ultimoElemento === count ? "disabled" : ""}`}
                  onClick={() => handlePagination("right")}
                  src={chevronRight}
                  alt=""
                />
              </div>
            ) : null}
            {/* 
        <iframe
            src="https://bazeapp.retool.com/apps/Ispezione%20DWH"
            title="BazeApp Ispezione DWH"
            width="100%"
            height="100%"
        />
        */}
          </div>
        </div>
        <Editor />
      </>
    );
  } else {
    return <h1>Loading...</h1>;
  }
};

export default DatabasePageFamilies;
