import React, { useEffect } from "react";
import Menu from "../../components/Menu";
import { IBoard } from "../../types/boards";
import { StyledKanban } from "./StyledKanban";
import { KanbanSpaces } from "../../constants/kanban";
import {
  DndContext,
  DragEndEvent,
  PointerSensor,
  pointerWithin,
  rectIntersection,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import Board from "./components/Board/Board";
import Editor from "../databaseAdmin/model-table/components/Editor";
import SearchBar from "../../components/atoms/SearchBar";
import { useDispatch, useSelector } from "react-redux";
import { getKanbanWorkers, updateKanbanBoard } from "../../store/thunks/kanban";
import { RootState } from "../../store/store";
import {
  setAvailableFilters,
  setFieldKeys,
} from "../../store/slices/databaseTable";
import { getFieldKeys, getFilterFields } from "../../utils/jsonUtils";
import Dropdown from "../../components/atoms/Dropdown/Dropdown";
import {
  ProvinciaItalia,
  provincieItaliaList,
} from "../../constants/provincie";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { app } from "../../lib/firebase/config";
import {
  registerUserToSession,
  setSelection,
} from "../../store/slices/vetrina";

const boards: IBoard = {
  0: {
    label: "Registrato",
    color: "#a0a0a0",
  },
  1: {
    label: "Profilo Controllato",
    color: "#d69a33",
  },
  2: {
    label: "Chiamato",
    color: "#337ad6",
  },
  3: {
    label: "Colloquiato",
    color: "#33d65e",
  },
  4: {
    label: "In Vetrina",
    color: "#50acf2",
  },
  5: {
    label: "Non Validato",
    color: "#d63333",
  },
  6: {
    label: "Non più disponibile",
    color: "#d8d8d8",
  },
};

const filterKeys = ["citta", "nome", "cognome", "codice_fiscale", "provincia"];

const Kanban = () => {
  const [filtering, setFiltering] = React.useState<boolean>(false);
  const [filterText, setFilterText] = React.useState<any>("");
  const [spaceCards, setSpaceCards] = React.useState<any>([]);
  const [lastDays, setLastDays] = React.useState<number>(120);
  const [lastDaysEdited, setLastDaysEdited] = React.useState<boolean>(false);
  const [currentSpace, setCurrentSpace] = React.useState<KanbanSpaces>(
    KanbanSpaces.workers
  );
  const [provinciaFilter, setProvinciaFilter] =
    React.useState<ProvinciaItalia | null>(null);

  const cards = useSelector((state: RootState) => state.kanban.cards);
  const dr = useDispatch<any>();

  const navigate = useNavigate();
  const dispatchRedux = useDispatch<any>();
  useEffect(() => {
    const auth = getAuth(app);
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const db = getFirestore(app);
        const docRef = collection(db, "internal_users");
        const q = query(docRef, where("email", "==", user.email));
        const querySnapshot = (await getDocs(q)) as any;
        if (querySnapshot.docs?.length === 0) {
          navigate(
            `/sign-in-admin?from=${encodeURIComponent(
              window.location.pathname
            )}`
          );
        } else {
          dispatchRedux(registerUserToSession(querySnapshot.docs[0].data()));
          dispatchRedux(
            setSelection(querySnapshot.docs[0].data().selection ?? [])
          );
        }
      } else {
        navigate(
          `/sign-in-admin?from=${encodeURIComponent(window.location.pathname)}`
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    switch (currentSpace) {
      case KanbanSpaces.workers:
        setSpaceCards(cards);
        break;
      // case KanbanSpaces.employers:
      //   setSpaceCards(cards);
      //   break;
      default:
        setSpaceCards(cards);
    }

    setSpaceCards((p: any) => {
      let currentList = [...p].sort(
        (a: any, b: any) =>
          new Date(b.edit_date ?? b.creation_time).getTime() -
          new Date(a.edit_date ?? a.creation_time).getTime()
      );

      if (filtering) {
        currentList = currentList.filter((item) => {
          let matchesProvincia = provinciaFilter
            ? item["provincia"] === provinciaFilter
            : true;
          let matchesFilterText = false;

          if (filterText?.length > 0) {
            matchesFilterText = filterKeys.some((key) =>
              item[key]
                ?.toString()
                .toLowerCase()
                .includes(filterText.toLowerCase())
            );
          } else {
            matchesFilterText = true;
          }

          return matchesProvincia && matchesFilterText;
        });
      }
      return currentList;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpace, filtering, cards]);

  useEffect(() => {
    if (filterText === "" || !provinciaFilter) setFiltering(false);
  }, [filterText, provinciaFilter]);

  function handleDragEnd(event: DragEndEvent) {
    dr(
      updateKanbanBoard({
        workerId: `${event.active.id}`,
        board: `${event.over?.id}`,
      })
    );
    setSpaceCards((p: any) => {
      const cpy = [...p];
      const index = cpy.findIndex(
        (c: any) => c.codice_fiscale === event.active.id
      );
      if (!event.over) return;
      const updatedObject = { ...cpy[index], board: +event.over.id };
      cpy[index] = updatedObject;
      return cpy;
    });
  }

  function customCollisionDetectionAlgorithm(args: any) {
    const pointerCollisions = pointerWithin(args);

    if (pointerCollisions.length > 0) {
      return pointerCollisions;
    }

    return rectIntersection(args);
  }

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  useEffect(() => {
    let filters = {};
    getFilterFields("workers").then((res) => {
      for (const [key, value] of Object.entries(res)) {
        if (value.hasOwnProperty("choices")) {
          filters = { ...filters, [key]: value };
        } else {
          for (const nValue of Object.values(value)) {
            if (nValue.hasOwnProperty("choices")) {
              filters = { ...filters, [nValue.key.replace(".", "_")]: nValue };
            }
          }
        }
      }
      dr(setAvailableFilters(filters));
    });
    getFieldKeys("workers").then((res) => dr(setFieldKeys(res)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filterText.length > 0 || provinciaFilter) return;
    dr(getKanbanWorkers({ lastDays })).finally(() => setFiltering(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtering]);

  const handleSearchButton = () => {
    setFiltering(true);

    if (lastDaysEdited) {
      dr(getKanbanWorkers({ lastDays })).finally(() => {
        setLastDaysEdited(false);
      });
    } else {
      setSpaceCards((p: any) => {
        let currentList = [...p].sort(
          (a: any, b: any) =>
            new Date(b.edit_date ?? b.creation_time).getTime() -
            new Date(a.edit_date ?? a.creation_time).getTime()
        );

        if (filtering) {
          currentList = currentList.filter((item) => {
            let matchesProvincia = provinciaFilter
              ? item["provincia"] === provinciaFilter
              : true;
            let matchesFilterText = false;

            if (filterText?.length > 0) {
              matchesFilterText = filterKeys.some((key) =>
                item[key]
                  ?.toString()
                  .toLowerCase()
                  .includes(filterText.toLowerCase())
              );
            } else {
              matchesFilterText = true;
            }

            return matchesProvincia && matchesFilterText;
          });
        }
        return currentList;
      });
    }
  };

  return (
    <StyledKanban $boardsCount={Object.keys(boards).length}>
      <Editor />
      <Menu initialItemSelected="Kanban"></Menu>
      <section className="content">
        {/* <div className="space-selectors-wrapper">
          {Object.values(KanbanSpaces).map((spaceKey) => {
            return (
              <button
                className={`space-selector ${
                  currentSpace === spaceKey ? "space-selector_selected" : ""
                }`}
                key={spaceKey}
                onClick={() => setCurrentSpace(spaceKey)}
              >
                {spaceKey}
              </button>
            );
          })}
        </div> */}
        <div className="main-section">
          <div className="search-text-wrapper">
            <SearchBar
              onCancel={() => {
                setFilterText("");
                setFiltering(false);
              }}
              onChange={(e) => setFilterText(e.target.value)}
              className="search-bar"
            />
          </div>
          <Dropdown
            className="provincia-dropdown"
            onClick={(provincia) =>
              setProvinciaFilter(provincia as ProvinciaItalia)
            }
            options={provincieItaliaList}
            placeholder="Provincia"
          />
          <div className="timeframe-wrapper">
            <div className="timeframe">
              <span className="timeframe__label">Timeframe</span>
              <span className="timeframe__value">
                Last{" "}
                <input
                  onChange={(e) => {
                    setLastDaysEdited(true);
                    setLastDays(+e.target.value);
                  }}
                  value={lastDays}
                  type="number"
                />{" "}
                days
              </span>
            </div>
          </div>
          <button onClick={handleSearchButton} className="search-btn">
            Search
          </button>
        </div>
        {!spaceCards.length ? (
          <p className="no-workers-text">Non ci sono lavoratori da mostrare</p>
        ) : (
          <></>
        )}
        <div className="boards-wrapper">
          <DndContext
            sensors={sensors}
            collisionDetection={customCollisionDetectionAlgorithm}
            onDragEnd={(event) => handleDragEnd(event)}
          >
            {Object.keys(boards).map((boardKey) => {
              return (
                <Board
                  key={boardKey}
                  color={boards[+boardKey].color}
                  label={boards[+boardKey].label}
                  boardKey={boardKey}
                  // cards={spaceCards?.filter(
                  //   (sb: any) => sb.board === +boardKey
                  // )}
                  cards={spaceCards}
                />
              );
            })}
          </DndContext>
        </div>
      </section>
    </StyledKanban>
  );
};

export default Kanban;
