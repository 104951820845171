import React, { useEffect, useState } from "react";
import "./style.css";
import Step1 from "./steps/step1_workOptionsList";
import StepWorkModalities from "./steps/step2_workModalitiesList.js";
import StepCertification from "./steps/step3_certification.js";
import DocumentAccordingToLaw from "./steps/step1_documentAccordingToLaw.js";
import AcceptableConditions from "./steps/step5_acceptableConditions.js";
import WhatWorkNotWantToDo from "./steps/step6_skillsThatNotHave.js";
import CarAvailability from "./steps/step7_carAvailability.js";
import DrivingLicense from "./steps/step8_drivingLicense.js";
import Gender from "./steps/step9_gender.js";
import BirthDate from "./steps/step10_bithDate.js";
import LanguageSpoken from "./steps/step11_languageSpoken.js";
import ItalianKnowledge from "./steps/step12_ItalianKnowledge.js";
import Nationality from "./steps/step13_nationality.js";
import Experience from "./steps/step14_experiences";
import NameAndSurname from "./steps/step15_name_surname.js";
import CodiceFiscale from "./steps/step16_codiceFiscale.js";
import EmailAndPhone from "./steps/step17_emailPhone.js";
import FinalStep from "./steps/step99_final.js";
import AddressStep from "./steps/AddressStep";
import { useDispatch, useSelector } from "react-redux";
import { getOnboardingOptions } from "../../utils/jsonUtils";
import {
  setExperiences,
  setOnboardingOptions,
} from "../../store/slices/onboarding";
import AboutChoicesStep from "./steps/AboutChoicesStep";
import AboutFreeTextStep from "./steps/AboutFreeTextStep";
import FirstStep from "./steps/FirstStep/FirstStep";
import DoveSeiNatoStep from "./steps/DoveSeiNatoStep/DoveSeiNatoStep";
import HardSkillStep from "./steps/HardSkillStep";

const OnboardingPage = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const [formData, setFormData] = useState({
    workOptionsList: [],
    workModalitiesList: [],
    nameCertification: [],
    //dateCertification:"", NON LO VOGLIONO
    documentAccordingToLaw: [],
    acceptableConditions: {
      badante: [],
      colf: [],
      baby_sitter: [],
    },
    //skillsThatNotHave: [],
    whatWorketsNotWantToDo: "",
    carAvailability: [],
    hardSkills: {
      badante: [],
      colf: [],
      baby_sitter: [],
    },
    drivingLicense: "",
    gender: [],
    birthDate: "",
    spokenLanguages: [],
    countryOrigin: "",
    italianKnowledge: [],
    nationality: "",
    experiences: [
      {
        experienceAs: "",
        // experienceTitle: "",
        startExperience: "",
        endExperience: "",
        workOptionExperience: [],
        workModalityExperience: [],
        mansioni: [],
      },
    ],
    //levelContract: [],
    name: "",
    surname: "",
    codiceFiscale: "",
    email: "",
    telephoneNumber: "",
    consentPrivacy: false,
    address: "",
    postalCode: "",
    province: "",
    city: "",
    describeSelfChoices: [],
    describeSelfFreeText: "",
  });

  const dr = useDispatch();

  const goToNextStep = (doubleStep = false) => {
    if (doubleStep === false) {
      setCurrentStep(currentStep + 1);
    } else {
      setCurrentStep(currentStep + 2);
    }
  };

  const goToPreviousStep = (doubleStep = false, i = undefined) => {
    if (i !== undefined) {
      setCurrentStep((p) => p - i);
    } else if (doubleStep === false && i === undefined) {
      setCurrentStep(currentStep - 1);
    } else {
      setCurrentStep(currentStep - 2);
    }
  };

  const onInputChange = (fieldName, value) => {
    setFormData((prevFormData) => {
      return {
        ...prevFormData,
        [fieldName]: value,
      };
    });
  };

  useEffect(() => {
    getOnboardingOptions().then((res) => {
      dr(setOnboardingOptions(res));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(formData);
  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <FirstStep
            onNext={goToNextStep}
            title="Stai cercando lavoro? Preparati a creare la tua candidatura lavorativa, segui la procedura ci vorranno solo pochi minuti."
            subtitle="Tieni a portata di mano il tuo codice fiscale, ti servirà per la registrazione."
          />
        );

      case 1:
        return (
          <Step1
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formData={formData}
            onInputChange={onInputChange}
            title="Che lavoro stai cercando?* (Obbligatorio)"
            subtitle="Puoi selezionare più opzioni."
          />
        );

      case 2:
        return (
          <StepWorkModalities
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formData={formData}
            onInputChange={onInputChange}
            title="In quali modalità puoi lavorare?* (Obbligatorio)"
            subtitle="Puoi selezionare più opzioni."
          />
        );

      case 3:
        return (
          <AcceptableConditions
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formData={formData}
            onInputChange={onInputChange}
            title="Nel tuo lavoro, sei disponibile a:* (Obbligatorio)"
            subtitle="Puoi selezionare più opzioni"
          />
        );

      case 4:
        return (
          <HardSkillStep
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formData={formData}
            onInputChange={onInputChange}
            title="Cosa sai fare meglio?* (Obbligatorio)"
            subtitle="Puoi selezionare massimo 5 opzioni"
          />
        );

      case 5:
        return (
          <ItalianKnowledge
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formData={formData}
            onInputChange={onInputChange}
            title="Qual è il tuo livello di Italiano?* (Obbligatorio)"
            subtitle="Puoi scegliere un unica opzione"
          />
        );

      case 6:
        return (
          <LanguageSpoken
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formData={formData}
            onInputChange={onInputChange}
            title="Quali tra queste lingue sai parlare?* (Obbligatorio)"
            subtitle="Puoi scegliere più opzioni"
          />
        );

      case 7:
        return (
          <StepCertification
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formData={formData}
            onInputChange={onInputChange}
            title="Hai una qualifica professionale o certificazione delle competenze"
            subtitle="Esempio: OSS, UNI, corso >500h, etc."
          />
        );

      case 8:
        return (
          <Experience
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formData={formData}
            onInputChange={onInputChange}
            title={`Descrivi la tua esperienza lavorativa più importante* (Obbligatorio)`}
            subtitle="Inserisci una o più esperienza/e lavorativa/e significativa/e che hai fatto"
          />
        );

      case 9:
        return (
          <AboutFreeTextStep
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formData={formData}
            placeholder="Sono una persona paziente e attenta, sempre pronta ad ascoltare e ad aiutare. Nel mio lavoro come badante, mi impegno sempre a creare un ambiente sereno e confortevole per chi assisto..."
            title="Che tipo di persona sei?"
            onInputChange={onInputChange}
          />
        );

      case 10:
        return (
          <DrivingLicense
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formData={formData}
            onInputChange={onInputChange}
            title="Hai la patente?* (Obbligatorio)"
            subtitle=""
          />
        );

      case 11:
        return (
          <CarAvailability
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formData={formData}
            onInputChange={onInputChange}
            title="Possiedi un auto?* (Obbligatorio)"
            subtitle=""
          />
        );

      case 12:
        return (
          <Gender
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formData={formData}
            onInputChange={onInputChange}
            title="Sei?* (Obbligatorio)"
            subtitle=""
          />
        );

      case 13:
        return (
          <BirthDate
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formData={formData}
            onInputChange={onInputChange}
            title="Qual è la tua data di nascita?* (Obbligatorio)"
            subtitle="Devi essere maggiorenne"
          />
        );

      case 14:
        return (
          <AddressStep
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formData={formData}
            title="Dove abiti?* (Obbligatorio)"
            onInputChange={onInputChange}
          />
        );

      case 15:
        return (
          <DoveSeiNatoStep
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formData={formData}
            onInputChange={onInputChange}
            title="Dove sei nato/a?* (Obbligatorio)"
          /> // nazionalita
        );

      case 16:
        return (
          <Nationality
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formData={formData}
            onInputChange={onInputChange}
            title="Quale cittadinanza hai?* (Obbligatorio)"
            subtitle=""
          /> // cittadinanza
        );

      case 17:
        return (
          <NameAndSurname
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formData={formData}
            title="Come ti chiami?* (Obbligatorio)"
            onInputChange={onInputChange}
          />
        );

      case 18:
        return (
          <CodiceFiscale
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formData={formData}
            title="Il tuo codice fiscale?* (Obbligatorio)"
            onInputChange={onInputChange}
          />
        );

      case 19:
        return (
          <EmailAndPhone
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formData={formData}
            title="Inserisci le tue informazioni di contatto* (Obbligatorio)"
            onInputChange={onInputChange}
          />
        );

      case 20:
        return (
          <FinalStep
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formData={formData}
            title="Grazie per la registrazione"
            subtitle="Ti contatteremo a breve con proposte di lavoro"
            onInputChange={onInputChange}
          />
        );

      default:
        return null;
    }
  };

  return (
    <div>
      <div>{renderStepContent()}</div>
    </div>
  );
};

export default OnboardingPage;
