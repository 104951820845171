import React, { useState } from "react";
import Button from "../../../components/Button/index";
import "../style.css";
import Logo from "../../../components/Logo/Logo";
import ErrorMessageInForm from "../../../components/ErrorMessageInForm";
import { isAcceptableBirthDate } from "../functionsSteps";
import DateForm from "../../../components/DateForm";
import { transformDateStringToFirestoreDate } from "../../../utils/utils.js";
import { isStepValid } from "../../../utils/onboarding";
import AvantiIndietroStepButtons from "../../../components/AvantiIndietroStepButtons";

const BirthDate = ({
  onNext,
  formData,
  onInputChange,
  onPrevious,
  title,
  subtitle,
}) => {
  const { birthDate } = formData;

  const [errorMessage, setErrorMessage] = useState("");

  const handleClickNextButton = () => {
    //console.log(workModalitiesList);
    if (new Date(formData.birthDate) < new Date("1930-01-01")) {
      setErrorMessage("Inserisci una data corretta.");
    } else if (
      new Date(formData.birthDate) >
      new Date(new Date().setFullYear(new Date().getFullYear() - 18))
    ) {
      setErrorMessage(
        "Inserisci una data corretta. Devi essere maggiorenne per utilizzare il servizio"
      );
    } else if (
      isStepValid({
        mandatoryFields: "birthDate",
        formData: {
          birthDate: formData.birthDate,
        },
      })
    ) {
      onNext();
    }
  };

  const handleClickPreviousButton = () => {
    //onInputChange("birthDate",[]);
    // onInputChange("gender", []);

    onPrevious();
  };

  return (
    <div className="onboarding-interno">
      <Logo></Logo>
      <div className="onboarding-screen">
        <div className="typeforms-buttons" style={{ overflow: "hidden" }}>
          <div className="title-container">
            <h1 className="title">{title} </h1>
          </div>
          <h2 className="subtitle">{subtitle}</h2>
          <div className="div">
            <DateForm
              value={birthDate}
              onChange={(value) => {
                onInputChange("birthDate", value.target.value);
              }}
            ></DateForm>
            <ErrorMessageInForm message={errorMessage} />
            <AvantiIndietroStepButtons
              onNext={handleClickNextButton}
              onPrevious={handleClickPreviousButton}
              nextDisabled={
                !isStepValid({
                  mandatoryFields: "birthDate",
                  formData: {
                    birthDate: formData.birthDate,
                  },
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BirthDate;
