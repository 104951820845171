import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../lib/firebase/functions";
import { RootState } from "../store";
import { IFamily, IWorker } from "../../types/filterFields";
import { updateKanbanCard } from "../slices/kanban";
import { client } from "../../lib/axios";

export const getWorkers = createAsyncThunk(
  "databaseTable/getWorkers",
  async (
    {
      currentPage: page,
      order,
      orderField,
    }: {
      currentPage: number;
      order: "asc" | "desc";
      orderField: string;
    },
    thunkAPI
  ): Promise<{ count: number; rows: IWorker[]; totalCount?: number }> => {
    const state = thunkAPI.getState() as RootState;
    const filters = state.databaseTable.queryFilters;

    const _filters = filters
      ?.map((f: any) => {
        let value = Object.values(f)[0];
        let key = Object.keys(f)[0];

        if (Array.isArray(value)) {
          value = value.map(encodeURIComponent).join(",");
        } else {
          value = encodeURIComponent(value as string);
        }

        return `${key}:${value}`;
      })
      .join(";");

    const response = await client.get("/database/workers", {
      params: {
        page,
        order,
        orderField,
        filters: _filters?.length ? _filters : undefined,
      },
    });

    return response.data as { count: number; rows: IWorker[] };
  }
);

export const getFamilies = createAsyncThunk(
  "databaseTable/getFamilies",
  async ({
    currentPage: page,
    order,
    orderField,
  }: {
    currentPage: number;
    order: string;
    orderField: string;
  }): Promise<{ count: number; rows: IFamily[] }> => {
    const response = await client.get("/database/families", {
      params: {
        page,
        order,
        orderField,
      },
    });

    return response.data as { count: number; rows: IFamily[] };
  }
);

export const searchWorkers = createAsyncThunk(
  "databaseTable/searchWorkers",
  async (_, thunkAPI): Promise<{ count: number; rows: IWorker[] }> => {
    const state = thunkAPI.getState() as RootState;
    let filters = [...(state.databaseTable.queryFilters ?? ([] as any))];

    if (!filters.some((f: any) => "text_search" in f)) {
      filters.push({ text_search: "" });
    }

    const _filters = filters
      ?.map((f: any) => {
        let value = Object.values(f)[0];
        let key = Object.keys(f)[0];

        if (Array.isArray(value)) {
          value = value.map(encodeURIComponent).join(",");
        } else {
          value = encodeURIComponent(value as string);
        }

        return `${key}:${value}`;
      })
      .join(";");

    const response = await client.get(
      "/database/workers/search?filters=" + _filters
    );

    return response.data as { count: number; rows: IWorker[] };
  }
);

export const searchFamilies = createAsyncThunk(
  "databaseTable/searchFamilies",
  async (_, thunkAPI): Promise<IFamily[]> => {
    const state = thunkAPI.getState() as RootState;
    let filters = [...(state.databaseTable.queryFilters ?? ([] as any))];

    if (!filters.some((f: any) => "text_search" in f)) {
      filters.push({ text_search: "" });
    }

    const _filters = filters
      ?.map((f: any) => {
        let value = Object.values(f)[0];
        let key = Object.keys(f)[0];

        if (Array.isArray(value)) {
          value = value.map(encodeURIComponent).join(",");
        } else {
          value = encodeURIComponent(value as string);
        }

        return `${key}:${value}`;
      })
      .join(";");

    const response = await client.get(
      "/database/families/search?filters=" + _filters
    );

    return response.data as IFamily[];
  }
);

export const editSelected = createAsyncThunk(
  "databaseTable/editSelected",
  async (type: string, thunkAPI): Promise<IWorker | IFamily> => {
    try {
      const state = thunkAPI.getState() as RootState;
      const dispatch = thunkAPI.dispatch;
      const selected = state.databaseTable.selected;

      if (selected) {
        const serializedObject: any = { ...selected };
        // Object.entries(selected).forEach(([key, value]) => {
        //   if (key === "experiences") {
        //   }
        // });
        serializedObject["codice_fiscale"] = selected["codice_fiscale"];
        const res = await client.patch("/database/workers", {
          selected: serializedObject,
          type,
        });
        dispatch(updateKanbanCard(selected));
        return res.data as IWorker | IFamily;
      }

      return {} as IWorker | IFamily;
    } catch (e) {
      console.log(e);
      return {} as IWorker | IFamily;
    }
  }
);
