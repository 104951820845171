// File: /src/components/AvantiIndietroStepButtons/index.ts
// Created Date: Tuesday September 3rd 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Tuesday September 3rd 2024 3:14:12 pm
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import AvantiIndietroStepButtons from "./AvantiIndietroStepButtons";

export default AvantiIndietroStepButtons;
