import React, { useEffect, useState } from "react";
import { StyledHardSkillStep } from "./StyledHardSkillStep";
import Button from "../../../../components/Button";
import ErrorMessageInForm from "../../../../components/ErrorMessageInForm";
import Logo from "../../../../components/Logo/Logo";
import SelectChoice from "../../../../components/SelectChoice/SelectChoise";
import { isStepValid } from "../../../../utils/onboarding";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { WorkType } from "../../../../constants/onboarding";
import Accordion from "../../../databaseAdmin/model-table/components/Accordion";
import { isAcceptableStep5 } from "../../functionsSteps";
import AvantiIndietroStepButtons from "../../../../components/AvantiIndietroStepButtons";

const HardSkillStep = ({
  onNext,
  formData,
  onPrevious,
  onInputChange,
  title,
  subtitle,
}: any) => {
  const { hardSkills: hardSkill } = formData;

  const workType = useSelector(
    (state: RootState) => state.onboarding.tipo_lavoro
  );
  const hardSkills = useSelector(
    (state: RootState) => state.onboarding.formOptions.experiences
  );
  const [errorMessage, setErrorMessage] = useState("");

  const handleClickNextButton = () => {
    if (isAcceptableStep5(hardSkill, workType)) {
      onNext();
    } else {
      setErrorMessage(
        "Se inserisci 'Nessuna di queste' non puoi inserire le altre 3 risposte"
      );
    }
  };

  const handleSelectChoiceClick = (
    isClicked: boolean,
    text: string,
    category: string
  ) => {
    const updatedHardSkill = { ...hardSkill };

    if (isClicked) {
      if (!updatedHardSkill[category].includes(text)) {
        updatedHardSkill[category].push(text);
      }
    } else {
      const index = updatedHardSkill[category].indexOf(text);
      if (index !== -1) {
        updatedHardSkill[category].splice(index, 1);
      }
    }
    if (updatedHardSkill[category].length > 5) {
      updatedHardSkill[category].shift();
    }
    onInputChange("hardSkills", updatedHardSkill);
  };

  const handleClickPreviousButton = () => {
    // onInputChange("hardSkills", {
    //   badante: [],
    //   colf: [],
    //   baby_sitter: [],
    // });

    onPrevious();
  };

  useEffect(() => {
    if (
      !["Badante", "Colf", "Babysitter", ""].some((v) =>
        workType.some((w) => v === w)
      )
    ) {
      onNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workType]);

  const provideChoices = (workTypeKey: string) => {
    // const choices: any[] = [];

    // workType.forEach((wt) => {
    //   switch (wt) {
    //     case WorkType.BADANTE:
    //       choices.push(...hardSkills.badante);
    //       break;
    //     case WorkType.COLF:
    //       choices.push(...hardSkills.colf);
    //       break;
    //     case WorkType.BABY_SITTER:
    //       choices.push(...hardSkills.baby_sitter);
    //       break;
    //     default:
    //       break;
    //   }
    // });

    // return choices;

    return hardSkills[workTypeKey as keyof typeof hardSkills] || [];
  };

  return (
    <StyledHardSkillStep>
      <div className="onboarding-interno">
        <Logo></Logo>
        <div className="onboarding-screen">
          <div className="typeforms-buttons">
            <div className="title-container">
              {/*<h1 className="title">Quali tra queste condizioni &nbsp;<b><u>NON</u></b>&nbsp; accetersi? </h1>*/}
              <h1 className="title">{title}</h1>
            </div>
            <h2 className="subtitle">{subtitle}</h2>

            <div className="div">
              <div className="selection-element-horrizonatal">
                <div className="accordions">
                  {workType.includes(WorkType.BADANTE) && (
                    <Accordion label="Badante">
                      {provideChoices("badante").map((item: string) => {
                        return (
                          <SelectChoice
                            isClickedProp={hardSkill["badante"].includes(item)}
                            key={item}
                            text={item}
                            onClick={(isClicked: boolean, text: string) =>
                              handleSelectChoiceClick(
                                isClicked,
                                text,
                                "badante"
                              )
                            }
                          />
                        );
                      })}
                    </Accordion>
                  )}
                  {workType.includes(WorkType.COLF) && (
                    <Accordion label="Colf">
                      {provideChoices("colf").map((item: string) => (
                        <SelectChoice
                          isClickedProp={hardSkill["colf"].includes(item)}
                          key={item}
                          text={item}
                          onClick={(isClicked: boolean, text: string) =>
                            handleSelectChoiceClick(isClicked, text, "colf")
                          }
                        />
                      ))}
                    </Accordion>
                  )}
                  {workType.includes(WorkType.BABY_SITTER) && (
                    <Accordion label="Baby Sitter">
                      {provideChoices("baby_sitter").map((item: string) => (
                        <SelectChoice
                          isClickedProp={hardSkill["baby_sitter"].includes(
                            item
                          )}
                          key={item}
                          text={item}
                          onClick={(isClicked: boolean, text: string) =>
                            handleSelectChoiceClick(
                              isClicked,
                              text,
                              "baby_sitter"
                            )
                          }
                        />
                      ))}
                    </Accordion>
                  )}
                </div>
              </div>
              <ErrorMessageInForm message={errorMessage} />
              <AvantiIndietroStepButtons
                onNext={handleClickNextButton}
                onPrevious={handleClickPreviousButton}
                nextDisabled={!isAcceptableStep5(hardSkill, workType)}
              />
            </div>
          </div>
        </div>
      </div>
    </StyledHardSkillStep>
  );
};

export default HardSkillStep;
