import React from "react";
import GenereTag from "./components/genderTag";
import YesNoTag from "./components/yesNoTag";
import { getFilterFields, isJson } from "../../../utils/jsonUtils";

const MemoizedYesNoTag = React.memo(YesNoTag);
const MemoizedGenereTag = React.memo(GenereTag);

const formatDate = (dateString) => {
  try {
    if (!dateString) return dateString ?? "";
    // return dateString.split("T")[0];

    return (
      dateString?.split("T")[0] + " " + dateString?.split("T")[1].split(".")[0]
    );
  } catch (err) {}
};

const createColumnFromTypeText = (field) => ({
  Header: field.label,
  accessor: field.key,
  seac: field.seac,
  filter: "fuzzyText",
  Cell: ({ value }) => {
    try {
      if (field.key === "creation_time" || field.key === "edit_date") {
        return formatDate(value);
      } else {
        return <p>{value}</p>;
      }
    } catch (err) {}
  },
});

const createColumnFromTypeChoice = (field, experiencesCount) => {
  if (field.key === "creation_time" || field.key === "edit_date") {
    return {
      Header: field.label,
      accessor: field.key,
      filter: "fuzzyText",
      seac: field.seac,
      Cell: ({ value }) => {
        return formatDate(value);
      },
    };
  }

  if (field.key === "available") {
    return {
      Header: field.label,
      accessor: field.key,
      filter: "fuzzyText",
      seac: field.seac,
      Cell: ({ value }) => {
        return <p>{value ? "Si" : "No"}</p>;
      },
    };
  }

  if (field.key === "placement_user") {
    return {
      Header: field.label,
      accessor: field.key,
      filter: "fuzzyText",
      seac: field.seac,
      Cell: ({ value }) => {
        return (
          <p
            dangerouslySetInnerHTML={{ __html: value ? "&#9989;" : "&#10060;" }}
          />
        );
      },
    };
  }

  if (field.badante || field.baby_sitter || field.colf) {
    const objects = [];

    Object.keys(field).forEach((key) => {
      const object = {
        Header: field[key].label,
        accessor: field[key].key,
        filter: "fuzzyText",
        seac: field.seac,
        disableSortBy: true,
        Cell: ({ value }) => {
          let content = value;

          if (Array.isArray(content)) {
            content = value.join(" - ");
          }

          if (isJson(value)) {
            const parsedNested = JSON.parse(value);

            Object.keys(parsedNested).forEach((nestedKey) => {
              if (Array.isArray(parsedNested[nestedKey])) {
                parsedNested[nestedKey] = parsedNested[nestedKey].join(" - ");
              }
            });
          }

          if (value?.length === 0) return <p>--</p>;

          return <p>{content ?? "--"}</p>;
        },
      };
      objects.push(object);
    });

    return objects;
  }

  if (field.experiences) {
    const objects = [];
    const experiences = field.experiences;

    experiences.count = experiences.length;

    for (let i = 0; i < 2; i++) {
      if (experiences[i]?.label) {
        experiences[i]?.label.replace("$", i + 1);
      }
      Object.keys(experiences[0].values).forEach((key) => {
        const object = {
          Header: `(${i + 1}) ${experiences[0].values[key].label.replace(
            "$",
            i + 1
          )}`,
          disableSortBy: true,
          accessor: `experiences.experiences[${i}].values.${key}`,
          filter: "fuzzyText",
          seac: field.seac,
          Cell: ({ value }) => {
            return <p>{value}</p>;
          },
        };
        if (object) {
          objects.push(object);
        }
      });
    }

    return objects;

    // return field.experiences.map((experience, i) => {
    //   const objects = [];

    //   if (experience.label) {
    //     experience.label.replace("$", field.count);
    //   }

    //   Object.keys(experience.values).forEach((key) => {
    //     const object = {
    //       Header: `(${i + 1}) ${experience.values[key].label.replace(
    //         "$",
    //         i + 1
    //       )}`,
    //       accessor: `experiences.values[${i}].${key}`,
    //       filter: "fuzzyText",
    //       seac: field.seac,
    //       Cell: ({ value }) => {
    //         return <p>{value}</p>;
    //       },
    //     };
    //     objects.push(object);
    //   });

    //   return objects;
    // });
  }

  return {
    Header: field.label,
    accessor: field.key,
    filter: "fuzzyText",
    seac: field.seac,
    Cell: ({ value }) => {
      if (
        field.choices &&
        field.choices.length === 2 &&
        field.choices.includes("Si") &&
        field.choices.includes("No")
      ) {
        return <MemoizedYesNoTag value={value} />;
      } else if (field.key === "genere") {
        return <MemoizedGenereTag value={value} />;
      } else {
        let content = value;

        if (Array.isArray(content)) {
          content = value.join(" - ");
        }

        if (isJson(value)) {
          const parsedNested = JSON.parse(value);

          Object.keys(parsedNested).forEach((nestedKey) => {
            if (Array.isArray(parsedNested[nestedKey])) {
              parsedNested[nestedKey] = parsedNested[nestedKey].join(" - ");
            }
          });
        }

        return <p>{content ?? "--"}</p>;
      }
    },
  };
};

export const getColumnModelWorkers = async () => {
  const fields = await getFilterFields("workers");

  let index = 0;
  const combinedFields = {};

  for (const [key, value] of Object.entries(fields)) {
    if (value.type === "text") {
      combinedFields[index] = createColumnFromTypeText(value);
    } else if (value.multiple) {
      combinedFields[index] = {
        ...createColumnFromTypeChoice(value),
        disableSortBy: true,
      };
    } else {
      combinedFields[index] = createColumnFromTypeChoice(value);
    }

    index++;
  }

  const combinedColumns = Object.values(combinedFields);

  const flattenedColumns = combinedColumns.flat(Infinity);
  return flattenedColumns;
};

function checkNestedMultiple(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (key === "multiple" && obj[key] === true) {
        return true;
      } else if (typeof obj[key] === "object") {
        if (checkNestedMultiple(obj[key])) {
          return true;
        }
      }
    }
  }
  return false;
}
