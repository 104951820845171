// File: /src/store/thunks/vetrina.ts
// Created Date: Wednesday October 16th 2024
// Author: Christian Nonis <alch.infoemail@gmail.com>
// -----
// Last Modified: Wednesday October 16th 2024 4:16:26 pm
// Modified By: the developer formerly known as Christian Nonis at <alch.infoemail@gmail.com>
// -----

import { createAsyncThunk } from "@reduxjs/toolkit";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../lib/firebase/functions";
import { IResponseWorkers } from "../../types/vetrina";
import { ProvinciaItalia } from "../../constants/provincie";
import { client } from "../../lib/axios";

interface IGetWorkers {
  lastDocId?: string;
  count: number;
  tipoLavoroDomestico: string[];
  tipoDiRapporto: string[];
  provincia: ProvinciaItalia;
}

export const getWorkers = createAsyncThunk(
  "vetrina/getWorkers",
  async ({
    lastDocId,
    count,
    tipoLavoroDomestico,
    tipoDiRapporto,
    provincia,
  }: IGetWorkers) => {
    try {
      const tipoLavoroDomesticoString = tipoLavoroDomestico?.length
        ? tipoLavoroDomestico.map(encodeURIComponent).join(",")
        : undefined;
      const tipoDiRapportoString = tipoDiRapporto?.length
        ? tipoDiRapporto.map(encodeURIComponent).join(",")
        : undefined;
      const response = await client.get("/vetrina/workers", {
        params: {
          lastDocId,
          count,
          tipo_lavoro_domestico: tipoLavoroDomesticoString,
          tipo_di_rapporto: tipoDiRapportoString,
          provincia: provincia ? encodeURIComponent(provincia) : undefined,
        },
      });
      return response.data as IResponseWorkers;
    } catch (e) {
      console.log((e as Error).message);
    }
  }
);
