import React from "react";
import { StyledFirstStep } from "./StyledFirstStep";
import Logo from "../../../../components/Logo/Logo";
import Button from "../../../../components/Button";
import image from "../../../../assets/onboarding-image.jpg";
import AvantiIndietroStepButtons from "../../../../components/AvantiIndietroStepButtons";

const FirstStep = ({ title, subtitle, onNext }: any) => {
  return (
    <StyledFirstStep>
      <div className="onboarding-interno">
        <Logo></Logo>
        <div className="onboarding-screen">
          <div className="typeforms-buttons grid-welcome">
            <div className="title-container">
              <h1 className="title">{title} </h1>
            </div>
            <img src={image} alt="" />
            <h2 className="subtitle">{subtitle}</h2>
            <div className="div">
              <div className="selection-element-horrizonatal">
                <AvantiIndietroStepButtons
                  onNext={() => onNext()}
                  onPrevious={() => window.history.back()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledFirstStep>
  );
};

export default FirstStep;
