import React, { useEffect } from "react";
import { StyledEditField } from "./StyledEditField";
import { IField } from "../../../types/filterFields";

interface IEditField {
  onChange: (e: string | string[]) => void;
  className?: string;
  value: string | string[];
  objKey: string;
  placeholder?: string;
  obj?: IField;
  multiple?: boolean;
}

const EditField = ({
  obj,
  onChange,
  className,
  value,
  objKey,
  placeholder = "",
  multiple = false,
}: IEditField) => {
  const [curValue, setCurValue] = React.useState<string | string[]>(
    multiple ? (value ? value : []) : value ? value : ""
  );

  useEffect(() => {
    setCurValue(value);
  }, [value]);

  const inputProps = Object.assign(
    {},
    placeholder && { placeholder },
    value && { value },
    className && { className }
  );

  return (
    <StyledEditField>
      {obj && obj.choices ? (
        <>
          {" "}
          <label>{placeholder}</label>
          <select
            multiple={true}
            value={
              typeof curValue === "string" || typeof curValue === "boolean"
                ? [...[curValue]]
                : curValue
            }
            onChange={(e) => {
              const selectedOptions = e.target.selectedOptions;
              const values = Array.from(selectedOptions).map(
                (option) => option.value
              );
              setCurValue(values);
              onChange(multiple ? values : values[0]);
            }}
            className={className}
            // {...inputProps}
          >
            <option>Seleziona</option>
            {obj.choices.map((choice) => (
              <option value={choice}>{choice}</option>
            ))}
          </select>
        </>
      ) : (
        <>
          <label>{placeholder}</label>
          <input
            type="text"
            value={curValue}
            onChange={(e) => onChange(e.target.value)}
            className={className}
            {...inputProps}
          />
        </>
      )}
    </StyledEditField>
  );
};

export default EditField;
