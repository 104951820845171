import React, { useEffect, useState } from "react";
import { StyledDoveSeiNatoStep } from "./StyledDoveSeiNatoStep";
import { isAcceptableStepFormText } from "../../functionsSteps";
import Logo from "../../../../components/Logo/Logo";
import TypeForm from "../../../../components/TypeForm";
import ErrorMessageInForm from "../../../../components/ErrorMessageInForm";
import Button from "../../../../components/Button";
import SelectChoice from "../../../../components/SelectChoice/SelectChoise";
import { COUNTRIES } from "../../../../constants/countries/countriesNationalities";
import { isStepValid } from "../../../../utils/onboarding";
import Dropdown from "../../../../components/atoms/Dropdown/Dropdown";
import AvantiIndietroStepButtons from "../../../../components/AvantiIndietroStepButtons";

const DoveSeiNatoStep = ({
  onNext,
  formData,
  onInputChange,
  onPrevious,
  title,
}: any) => {
  const { countryOrigin } = formData;

  const [errorMessage, setErrorMessage] = useState("");

  const handleClickNextButton = () => {
    //console.log(workModalitiesList);
    if (isAcceptableStepFormText(countryOrigin)) {
      onNext();
    } else {
      setErrorMessage("Inserisci uno stato di origine valido");
    }
  };

  const handleClickPreviousButton = () => {
    // onInputChange("countryOrigin", []);

    onPrevious();
  };

  const handleSelectChoiceClick = (text: string | string[]) => {
    onInputChange("countryOrigin", text as string);
  };

  return (
    <StyledDoveSeiNatoStep>
      <div className="onboarding-interno">
        <Logo></Logo>
        <div className="onboarding-screen">
          <div className="typeforms-buttons">
            <div className="title-container">
              <h1 className="title">{title}</h1>
            </div>

            <div className="div">
              <Dropdown
                defaultValue={
                  formData["countryOrigin"] ?? "Seleziona un opzione"
                }
                options={COUNTRIES}
                onClick={handleSelectChoiceClick}
                placeholder="Seleziona un paese"
              />
              {/* <div className="selection-element-horrizonatal">
                {COUNTRIES.map((item) => (
                  <SelectChoice
                    isClickedProp={item === formData["countryOrigin"]}
                    key={item}
                    text={item}
                    onClick={handleSelectChoiceClick}
                  />
                ))}
              </div> */}

              <ErrorMessageInForm message={errorMessage} />
              <AvantiIndietroStepButtons
                onNext={handleClickNextButton}
                onPrevious={handleClickPreviousButton}
                nextDisabled={
                  !isStepValid({
                    mandatoryFields: "countryOrigin",
                    formData: {
                      countryOrigin: formData.countryOrigin,
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </StyledDoveSeiNatoStep>
  );
};

export default DoveSeiNatoStep;
