import React, { useEffect, useState } from "react";
import Button from "../../../components/Button";
import Logo from "../../../components/Logo/Logo";
import ErrorMessageInForm from "../../../components/ErrorMessageInForm";
import SelectChoice from "../../../components/SelectChoice/SelectChoise";
import {
  isAcceptableStepMultipleOptions,
  isAcceptableStep5,
} from "../functionsSteps";
import { useSelector } from "react-redux";
import Accordion from "../../databaseAdmin/model-table/components/Accordion";
import { WorkType } from "../../../constants/onboarding";
import AvantiIndietroStepButtons from "../../../components/AvantiIndietroStepButtons";

const AcceptableConditions = ({
  onNext,
  formData,
  onPrevious,
  onInputChange,
  title,
  subtitle,
}) => {
  const { acceptableConditions } = formData;
  const [errorMessage, setErrorMessage] = useState("");

  const workAvailabilities = useSelector(
    (state) => state.onboarding.formOptions.workAvailabilities
  );
  const workType = useSelector((state) => state.onboarding.tipo_lavoro);

  const handleSelectChoiceClick = (isClicked, text, category) => {
    const updatedAcceptableConditions = { ...acceptableConditions };

    if (isClicked) {
      if (!updatedAcceptableConditions[category].includes(text)) {
        updatedAcceptableConditions[category].push(text);
      }
    } else {
      const index = updatedAcceptableConditions[category].indexOf(text);
      if (index !== -1) {
        updatedAcceptableConditions[category].splice(index, 1);
      }
    }
    if (updatedAcceptableConditions[category].length > 5) {
      updatedAcceptableConditions[category].shift();
    }
    onInputChange("acceptableConditions", updatedAcceptableConditions);
  };

  const handleClickNextButton = () => {
    if (isAcceptableStep5(acceptableConditions, workType)) {
      onNext();
    } else {
      setErrorMessage(
        "Se inserisci 'Nessuna di queste' non puoi inserire le altre 3 risposte"
      );
    }
  };

  const handleClickPreviousButton = () => {
    // onInputChange("acceptableConditions", {
    //   badante: [],
    //   colf: [],
    //   baby_sitter: [],
    // });
    onPrevious();
  };

  useEffect(() => {
    if (
      !["Badante", "Colf", "Babysitter", ""].some((v) =>
        workType.some((w) => v === w)
      )
    ) {
      onNext();
    }
  }, [workType]);

  const provideChoices = (workTypeKey) => workAvailabilities[workTypeKey] || [];

  return (
    <div className="onboarding-interno">
      <Logo />
      <div className="onboarding-screen">
        <div className="typeforms-buttons">
          <div className="title-container">
            <h1 className="title">{title}</h1>
          </div>
          <h2 className="subtitle">{subtitle}</h2>
          <div className="div">
            <div className="selection-element-horrizonatal">
              <div className="accordions">
                {workType.includes(WorkType.BADANTE) && (
                  <Accordion label={"Badante"}>
                    {provideChoices("badante").map((item) => (
                      <SelectChoice
                        key={item}
                        text={item}
                        isClickedProp={acceptableConditions.badante.includes(
                          item
                        )}
                        onClick={(isClicked, text) =>
                          handleSelectChoiceClick(isClicked, text, "badante")
                        }
                      />
                    ))}
                  </Accordion>
                )}
                {workType.includes(WorkType.COLF) && (
                  <Accordion label={"Colf"}>
                    {provideChoices("colf").map((item) => (
                      <SelectChoice
                        key={item}
                        isClickedProp={acceptableConditions.colf.includes(item)}
                        text={item}
                        onClick={(isClicked, text) =>
                          handleSelectChoiceClick(isClicked, text, "colf")
                        }
                      />
                    ))}
                  </Accordion>
                )}
                {workType.includes(WorkType.BABY_SITTER) && (
                  <Accordion label={"Babysitter"}>
                    {provideChoices("baby_sitter").map((item) => (
                      <SelectChoice
                        key={item}
                        isClickedProp={acceptableConditions.baby_sitter.includes(
                          item
                        )}
                        text={item}
                        onClick={(isClicked, text) =>
                          handleSelectChoiceClick(
                            isClicked,
                            text,
                            "baby_sitter"
                          )
                        }
                      />
                    ))}
                  </Accordion>
                )}
              </div>
            </div>
            <ErrorMessageInForm message={errorMessage} />
            <AvantiIndietroStepButtons
              onNext={handleClickNextButton}
              onPrevious={handleClickPreviousButton}
              nextDisabled={!isAcceptableStep5(acceptableConditions, workType)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcceptableConditions;
