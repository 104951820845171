import React, { useState } from "react";
import Button from "../../components/Button/index";
import { IconInterfacesUser } from "../../components/IconInterfacesUser";
import { TypeForm } from "../../components/TypeForm/index";
import "./style.css";
import bazeLogo from "../../img/static/bazeLogo.png";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { isEmail } from "../../utils/utils";
import ErrorMessageInForm from "../../components/ErrorMessageInForm/index";
import emailIcon from "../../img/static/mailIcon.svg";
import OkMessageInForm from "../../components/OkMessageInForm";
import Logo from "../../components/Logo/Logo";

export const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [okMessage, setOkMessage] = useState("");
  const auth = getAuth();
  auth.languageCode = "it";

  const handleClickLoginButton = () => {
    setErrorMessage("");
    setOkMessage("");
    if (isEmail(email)) {
      sendPasswordResetEmail(auth, email)
        .catch(() => {})
        .finally(() => {
          // Password reset email sent!
          // ..
          setOkMessage(
            "Abbiamo preso in carico la tua richiesta, se l'email è corretta ti arriva un mail in cui puoi modificare la password"
          );
        });
      // .catch((error) => {
      //   const errorCode = error.code;
      //   const errorMessage = error.message;
      //   // ..
      // });
    } else {
      setErrorMessage("Inserisci una mail valida");
    }
  };

  const handleInputChange = (value) => {
    setEmail(value);
  };

  return (
    <div className="log-in-interno">
      {/* <img className="logo" alt="Logo" src={bazeLogo} /> */}
      <Logo />
      <div className="login-screen">
        <div className="typeforms-buttons">
          <div className="title-container">
            <h1 className="title">Ho dimenticato la password</h1>
          </div>

          <h2 className="subtitle">Inserisci la tua mail di registrazione </h2>
          <div className="div">
            <div className="div">
              <TypeForm
                className="type-form-instance"
                label="Email"
                override={
                  <IconInterfacesUser
                    className="icon-interfaces-user-instance"
                    combinedShape={emailIcon}
                  />
                }
                value={email}
                onChange={(value) => handleInputChange(value)}
              />
            </div>
            <div className="other-typeform">
              {/*
              <div className="ricordami-check">
                <Checkbox className="design-component-instance-node" property1="checked" />
                <div className="text-wrapper">Ricordami</div>
              </div>
              <div className="text-wrapper-2" onClick={forgetPassword}>Hai dimenticato la password?</div>*/}
            </div>
          </div>
          <>
            <ErrorMessageInForm message={errorMessage}></ErrorMessageInForm>
            <OkMessageInForm message={okMessage}></OkMessageInForm>
          </>
          <div className="buttons">
            <Button
              className="button-instance"
              property1="primary"
              text="Conferma email"
              onClick={handleClickLoginButton}
            />
            <Button
              className="button-instance"
              property1="secondary"
              text="Torna alla pagina iniziale"
              onClick={() => navigate("/sign-in")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
