import React, { useState } from "react";
import Button from "../../components/Button/index";
import { IconInterfacesLock } from "../../components/IconInterfacesLock";
import { TypeForm } from "../../components/TypeForm/index";
import "./style.css";
import Logo from "../../components/Logo/Logo";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  applyActionCode,
  deleteUser,
} from "firebase/auth";
import ErrorMessageInForm from "../../components/ErrorMessageInForm";
import axios from "axios";
import { IconInterfacesUser } from "../../components/IconInterfacesUser";
import phoneIcon from "../../img/static/phoneIcon.svg";
import emailIcon from "../../img/static/mailIcon.svg";
import passwordIcon from "../../img/static/passwordIcon.svg";
import Checkbox from "../../components/Checkbox/index.js";

import { useDispatch } from "react-redux";

import image from "../../assets/onboarding-image.jpg";

import {
  isCodiceFiscale,
  getStringLength,
  isEmail,
  isPhoneNumber,
} from "../../utils/utils";

import { useNavigate } from "react-router-dom";
import { PREFIXES } from "../../constants/phonePrefixes/prefixes";
import { isStepValid } from "../../utils/onboarding";
import { setIsLoading } from "../../store/slices/databaseTable";
import { provincieItaliaList } from "../../constants/provincie";
import Dropdown from "../../components/atoms/Dropdown/Dropdown";
import { AVAILABLE_PROVINCIE } from "../../constants/vetrina/Other";
import { registerUserToSession } from "../../store/slices/vetrina";
import { endSignUpWith2FA, startSignUpWith2FA } from "../../utils/auth/2FA";
import { NotifyTypes } from "../Notify/Notify";
import { app } from "../../lib/firebase/config";
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { saveSession } from "../../utils/auth/session";
import { client } from "../../lib/axios";
import AvantiIndietroStepButtons from "../../components/AvantiIndietroStepButtons";

const SignUpFamilyPage = () => {
  const [currentStep, setCurrentStep] = useState(0);

  const [formValues, setFormValues] = useState({
    name: "",
    surname: "",
    codiceFiscale: "",
    cellulare: "",
    email: "",
    confermaPassword: "",
    nuovaPassword: "",
    provincia: "",
  });

  const goToNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const goToPreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleInputChange = (fieldName, value) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [fieldName]: value,
    }));
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <Step0
            onNext={goToNextStep}
            formValues={formValues}
            onInputChange={handleInputChange}
          />
        );
      case 1:
        return (
          <Step1
            onNext={goToNextStep}
            formValues={formValues}
            onInputChange={handleInputChange}
          />
        );
      case 2:
        return (
          <Step2
            onNext={goToNextStep}
            onPrevious={goToPreviousStep}
            formValues={formValues}
            onInputChange={handleInputChange}
          />
        );
      case 3:
        return (
          <Step3
            onPrevious={goToPreviousStep}
            formValues={formValues}
            onInputChange={handleInputChange}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <div>{renderStepContent()}</div>
    </div>
  );
};

const Step0 = ({ onNext, formValues, onInputChange }) => {
  const handleClickNextButton = () => {
    onNext();
  };

  const handleInputChange = (fieldName, value) => {
    onInputChange(fieldName, value);
  };

  return (
    <div className="sign-up-interno">
      <div className="onboarding-interno">
        <Logo></Logo>
        <div className="onboarding-screen">
          <div className="typeforms-buttons grid-welcome">
            <div className="title-container">
              <h1 className="title">
                Il nostro portale ti mette in contatto con lavoratori affidabili
                e competenti, registrati subito per scoprirli!
              </h1>
            </div>
            <img src={image} alt="" />
            <h2 className="subtitle">
              Il portale è riservato solo agli associati Assindatcolf.
            </h2>
            <div className="div">
              <div className="selection-element-horrizonatal">
                <AvantiIndietroStepButtons
                  onNext={handleClickNextButton}
                  onPrevious={() => window.history.back()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Step1 = ({ onNext, formValues, onInputChange }) => {
  const { name, surname, codiceFiscale } = formValues;
  const [errorMessage, setErrorMessage] = useState("");

  const handleClickNextButton = () => {
    if (!isCodiceFiscale(codiceFiscale)) {
      setErrorMessage("Inserisci un codice fiscale valido");
    } else if (!formValues.provincia || formValues.provincia === "") {
      setErrorMessage("Seleziona una provincia");
    } else if (name === "") {
      setErrorMessage("Inserisci un nome");
    } else if (surname === "") {
      setErrorMessage("Inserisci un cognome");
    } else {
      onNext();
    }
  };

  const handleInputChange = (fieldName, value) => {
    onInputChange(fieldName, value);
  };

  return (
    <div className="sign-up-interno">
      <Logo></Logo>
      <div className="sign-up-screen">
        <div className="typeforms-buttons">
          <div className="title-container">
            <h1 className="title">Crea il tuo profilo</h1>
          </div>
          <div class="div">
            <Dropdown
              className="dropdown-instance"
              options={provincieItaliaList}
              label="Dove stai cercando il lavoratore?*"
              onClick={(value) => handleInputChange("provincia", value)}
            />
            <TypeForm
              className="type-form-instance"
              label="Nome dell'associato"
              value={name}
              onChange={(value) => handleInputChange("name", value)}
            />
            <TypeForm
              className="type-form-instance"
              label="Cognome dell'associato"
              value={surname}
              onChange={(value) => handleInputChange("surname", value)}
            />
            <TypeForm
              className="type-form-instance"
              label="Codice Fiscale dell'associato"
              value={codiceFiscale}
              onChange={(value) =>
                handleInputChange("codiceFiscale", value.toUpperCase())
              }
            />
            <ErrorMessageInForm message={errorMessage} />
            <div className="buttons">
              <Button
                className={`button-instance ${
                  !isStepValid({
                    mandatoryFields: ["name", "surname", "codiceFiscale"],
                    formData: {
                      name,
                      surname,
                      codiceFiscale,
                    },
                  })
                    ? "button-disabled"
                    : ""
                }`}
                property1="primary"
                text="Avanti"
                onClick={handleClickNextButton}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Step2 = ({ onNext, onPrevious, formValues, onInputChange }) => {
  const { email, cellulare } = formValues;
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedPrefix, setSelectedPrefix] = useState(PREFIXES[0].prefix);

  const handleClickNextButton = () => {
    if (isEmail(email)) {
      if (isPhoneNumber(cellulare)) {
        onNext();
      } else {
        setErrorMessage("Inserisci un numero di telefono valido");
      }
    } else {
      setErrorMessage("Inserisci una mail valida");
    }
  };

  const handleClickPreviousButton = () => {
    onPrevious();
  };

  const handleInputChange = (fieldName, value) => {
    onInputChange(fieldName, value);
  };

  return (
    <div className="log-in-interno">
      <Logo></Logo>
      <div className="login-screen">
        <div className="typeforms-buttons">
          <div className="title-subtitle-container">
            <h1 className="title">Crea il tuo profilo</h1>
            <h3 className="subtitle-tel">
              Inserisci contatti di cui hai accesso, ti verrà inviato un codice
              di verifica al numero di telefono che inserirai
            </h3>
          </div>
          <div className="div">
            <TypeForm
              className="type-form-instance"
              label="Email"
              override={
                <IconInterfacesUser
                  className="icon-interfaces-user-instance"
                  combinedShape={emailIcon}
                />
              }
              value={email}
              onChange={(value) =>
                handleInputChange("email", value.toLowerCase())
              }
            />
            <TypeForm
              className="type-form-instance"
              label="Cellulare"
              prefix={
                <div className="wrapper-select-prefix">
                  <p>{selectedPrefix}</p>
                  <select
                    className="select-number-prefix"
                    onChange={(e) => {
                      setSelectedPrefix(e.target.value.trim());
                    }}
                  >
                    {PREFIXES.map((prefix) => (
                      <option key={prefix.prefix} value={`${prefix.prefix} `}>
                        {prefix.prefix} {prefix.country}
                      </option>
                    ))}
                  </select>
                </div>
              }
              override={
                <IconInterfacesUser
                  className="icon-interfaces-user-instance"
                  combinedShape={phoneIcon}
                />
              }
              onChange={(value) => {
                const updatedValue = `${selectedPrefix.trim()}${value.trim()}`;
                handleInputChange("cellulare", updatedValue);
              }}
            />
            <ErrorMessageInForm message={errorMessage} />
          </div>
          <AvantiIndietroStepButtons
            onNext={handleClickNextButton}
            onPrevious={handleClickPreviousButton}
            nextDisabled={
              !isStepValid({
                mandatoryFields: ["email", "cellulare"],
                formData: {
                  email,
                  cellulare,
                },
              })
            }
          />
        </div>
      </div>
    </div>
  );
};

const Step3 = ({ onPrevious, formValues, onInputChange }) => {
  const { confermaPassword, nuovaPassword } = formValues;
  const [errorMessage, setErrorMessage] = useState("");
  const [isChecked, setIsChecked] = useState("unchecked");

  const navigate = useNavigate();

  const handleCheckboxChange = (isChecked) => {
    setIsChecked(isChecked ? "checked" : "unchecked");
  };

  const auth = getAuth();

  const handleClickPreviousButton = () => {
    onPrevious();
  };

  const dr = useDispatch();

  const handleClickSignUpButton = async () => {
    dr(setIsLoading(true));
    if (nuovaPassword === confermaPassword) {
      // Perform additional actions here
      if (getStringLength(nuovaPassword) >= 6) {
        if (isChecked === "checked") {
          const {
            name,
            surname,
            codiceFiscale,
            cellulare,
            email,
            nuovaPassword,
          } = formValues;
          let user = null;

          try {
            const auth = getAuth(app);

            const db = getFirestore(app);

            const docRef = query(
              collection(db, "external_users"),
              where("numero_telefono", "==", cellulare)
            );

            const docSnap = await getDocs(docRef);

            if (docSnap.docs.length > 0) {
              setErrorMessage("Numero di telefono già registrato");
              dr(setIsLoading(false));
              return;
            }

            user = await createUserWithEmailAndPassword(
              auth,
              email,
              nuovaPassword
            );

            const res = await client.post("/auth/family/register", {
              name: name,
              surname: surname,
              codiceFiscale: codiceFiscale,
              cellulare: cellulare,
              email: email,
              nuovaPassword: nuovaPassword,
              provincia: formValues.provincia,
              placement_user: true,
            });

            saveSession({ codiceFiscale });

            let url = "https://cercalatuacolf.assindatcolf.it";

            if (
              process.env.REACT_APP_PROJECT_ID ===
              "assindatcolf-recruit-dwh-dev"
            ) {
              url = "http://localhost:3000";
            }

            if (
              process.env.REACT_APP_PROJECT_ID ===
              "assindatcolf-recruit-dwh-stage"
            ) {
              url = "https://assindatcolf-recruit-dwh-stage.web.app";
            }

            if (!res.data.success) {
              setErrorMessage(
                res.data.message ??
                  "Non risulti associato, il servizio è riservato ai soli associati"
              );

              dr(setIsLoading(false));

              await deleteUser(user.user);

              return;
            }

            await sendEmailVerification(user.user, {
              url: url + "/r/impl-2fa?cf=" + encodeURIComponent(codiceFiscale),
            });

            if (!user) {
              setErrorMessage(
                "Errore: si è verificato un errore, riprova più tardi"
              );
              dr(setIsLoading(false));

              return;
            }

            // if (!errorMessage || errorMessage.length === 0) {
            navigate(
              `/notify?type=${
                NotifyTypes.VERIFY_EMAIL
              }&m=${`Per completare la registrazione, controlla la tua casella di posta elettronica all'indirizzo ${email} e clicca sul link che ti abbiamo inviato.`}`
            );
            // }
          } catch (error) {
            console.error(
              "Errore nella chiamata alla funzione su Google Cloud:",
              error
            );
            setErrorMessage(
              "Errore: " + error?.message ?? "Errore sconosciuto"
            );
            dr(setIsLoading(false));
          }
        } else {
          setErrorMessage("Accetta l'informativa di privacy");
        }
      } else {
        setErrorMessage("Inserisci una password più lunga di 6 caratteri");
      }
    } else {
      setErrorMessage("Inserisci le stesse password");
    }
    dr(setIsLoading(false));
  };

  const handleInputChange = (fieldName, value) => {
    onInputChange(fieldName, value);
  };

  return (
    <div className="log-in-interno">
      <Logo></Logo>
      <div className="login-screen">
        <div className="typeforms-buttons">
          <div className="title-container">
            <h1 className="title">Crea il tuo profilo</h1>
          </div>
          <div className="div">
            <div className="div">
              <TypeForm
                className="type-form-instance"
                isPassword={true}
                label="Nuova Password"
                override={
                  <IconInterfacesUser
                    className="icon-interfaces-user-instance"
                    combinedShape={passwordIcon}
                  />
                }
                value={nuovaPassword}
                onChange={(value) => handleInputChange("nuovaPassword", value)}
              />
              <TypeForm
                className="type-form-instance"
                isPassword={true}
                label="Conferma Password"
                override={
                  <IconInterfacesUser
                    className="icon-interfaces-user-instance"
                    combinedShape={passwordIcon}
                  />
                }
                value={confermaPassword}
                onChange={(value) =>
                  handleInputChange("confermaPassword", value)
                }
              />
              <Checkbox
                isChecked={isChecked}
                onChange={handleCheckboxChange}
                page="/privacy-famiglia"
              ></Checkbox>
              <ErrorMessageInForm message={errorMessage} />

              <div id="captcha-container"></div>

              <div className="buttons">
                <Button
                  id="sign-up-button"
                  className={`button-instance ${
                    !isStepValid({
                      mandatoryFields: ["confermaPassword", "nuovaPassword"],
                      formData: {
                        confermaPassword,
                        nuovaPassword,
                      },
                    })
                      ? "button-disabled"
                      : ""
                  }`}
                  property1="primary"
                  text="Invia"
                  onClick={handleClickSignUpButton}
                />
                <Button
                  className="button-instance"
                  property1="secondary"
                  text="Indietro"
                  onClick={handleClickPreviousButton}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpFamilyPage;
