import React, { useEffect } from "react";
import { StyledPreCheckout } from "./StyledPreCheckout";
import image from "../../../assets/onboarding-image.jpg";
import Button from "../components/Button";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import axios from "axios";
import { getAuth } from "firebase/auth";
import { app } from "../../../lib/firebase/config";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { client } from "../../../lib/axios";

const PreCheckout = () => {
  const auth = getAuth(app);
  const [codiceFiscale, setCodiceFiscale] = React.useState<any>(null);

  const navigate = useNavigate();

  const handleCheckout = async () => {
    try {
      // let stripeSessionUrl =
      //   process.env.REACT_APP_PROJECT_ID === "assindatcolf-recruit-dwh-dev"
      //     ? `http://localhost:5001/${process.env.REACT_APP_PROJECT_ID}/us-central1/createstripesession`
      //     : "https://us-central1-assindatcolf-recruit-dwh-prod.cloudfunctions.net/createstripesession";
      // if (
      //   process.env.REACT_APP_PROJECT_ID === "assindatcolf-recruit-dwh-stage"
      // ) {
      //   stripeSessionUrl =
      //     "https://assindatcolf-recruit-dwh-stage.web.app/createstripesession";
      // }
      const res = await client.post("/payments/stripe/session/create", {
        uid: codiceFiscale,
      });
      window.location.href = res.data.url;
    } catch (e) {
      console.log(e);
    }
  };

  const getUser = async () => {
    const db = getFirestore(app);
    const userRef = collection(db, "external_users");
    const q = query(userRef, where("email", "==", auth.currentUser?.email));
    const querySnapshot = await getDocs(q);

    setCodiceFiscale(querySnapshot.docs?.[0]?.data()?.codice_fiscale);
  };

  auth.onAuthStateChanged(() => {
    if (!auth.currentUser) {
      navigate(`/sign-in?from=${encodeURIComponent(window.location.pathname)}`);
      return;
    } else {
      getUser();
    }
  });

  const createOrder = async () => {
    // let paypalUrl =
    //   process.env.REACT_APP_PROJECT_ID === "assindatcolf-recruit-dwh-dev"
    //     ? `http://localhost:5001/${process.env.REACT_APP_PROJECT_ID}/us-central1/createpaypalorder`
    //     : "https://us-central1-assindatcolf-recruit-dwh-prod.cloudfunctions.net/createpaypalorder";
    // if (process.env.REACT_APP_PROJECT_ID === "assindatcolf-recruit-dwh-stage") {
    //   paypalUrl =
    //     "https://assindatcolf-recruit-dwh-stage.web.app/createpaypalorder";
    // }
    return client
      .post("/payments/paypal/order/create", {
        uid: codiceFiscale,
        cart: [
          {
            id: "0001",
            quantity: "1",
          },
        ],
      })
      .then((order) => order.data.id);
  };
  async function onApprove(data: { orderID: string }) {
    // let confirmUrl =
    //   process.env.REACT_APP_PROJECT_ID === "assindatcolf-recruit-dwh-dev"
    //     ? `http://localhost:5001/${process.env.REACT_APP_PROJECT_ID}/us-central1/confirmpaypalorder`
    //     : "https://us-central1-assindatcolf-recruit-dwh-prod.cloudfunctions.net/confirmpaypalorder";
    // if (process.env.REACT_APP_PROJECT_ID === "assindatcolf-recruit-dwh-stage") {
    //   confirmUrl =
    //     "https://assindatcolf-recruit-dwh-stage.web.app/confirmpaypalorder";
    // }
    await client
      .post("/payments/paypal/order/capture", {
        orderID: data.orderID,
      })
      .then(() => {
        navigate("/notify?pcs=succ");
      });
  }

  if (!codiceFiscale) return <></>;

  return (
    <StyledPreCheckout>
      <div className="modal">
        <div className="card-highlight">
          <img src={image} alt="" />
          <h3>€29,90</h3>
          <p>Sblocco 4 contatti</p>
        </div>
        <p className="help-text">
          Preparati, verrai portato alla cassa dove potrai pagare la tariffa di
          <b>€29,90</b> e sbloccare i contatti dei lavoratori, riceverai via
          mail i contatti di ognuno di loro.
        </p>
        <div className="buttons-wrapper">
          <PayPalScriptProvider
            options={{
              currency: "EUR",
              clientId:
                process.env.REACT_APP_PAYPAL_CLIENT_ID ??
                "AT6ZHAENdM6o-iFiBci5zhlFYsI3xwKBLFKlrUAcGImJn0j3UZ9eN6UgWJkd7h_uis6OYClVUvbd1uj2",
            }}
          >
            <PayPalButtons
              className="paypal-button"
              style={{ layout: "horizontal", height: 45 }}
              createOrder={createOrder}
              onApprove={onApprove}
            />
          </PayPalScriptProvider>
          <Button
            children={"Continua e Paga"}
            onClick={function (): void {
              handleCheckout();
            }}
          />
        </div>
      </div>
    </StyledPreCheckout>
  );
};

export default PreCheckout;
