import React, { useState } from "react";
import Button from "../../../components/Button/index";
import "../style.css";
import Logo from "../../../components/Logo/Logo";
import ErrorMessageInForm from "../../../components/ErrorMessageInForm";
import SelectChoice from "../../../components/SelectChoice/SelectChoise";
import { stepElevenSpokenLanguage } from "../stepsVariables";
import { isAcceptableStepMultipleOptions } from "../functionsSteps";
import { isStepValid } from "../../../utils/onboarding";
import Dropdown from "../../../components/atoms/Dropdown/Dropdown";
import { NATIONALITIES } from "../../../constants/countries/countriesNationalities";
import AvantiIndietroStepButtons from "../../../components/AvantiIndietroStepButtons";

const LanguageSpoken = ({
  onNext,
  formData,
  onInputChange,
  onPrevious,
  title,
  subtitle,
}) => {
  const { spokenLanguages } = formData;

  const [errorMessage, setErrorMessage] = useState("");

  const handleClickNextButton = () => {
    //console.log(workModalitiesList);
    //console.log(formData);
    if (isAcceptableStepMultipleOptions(spokenLanguages)) {
      onNext();
    } else {
      setErrorMessage("Inserisci almeno una lingua che sai parlare");
    }
  };

  const handleClickPreviousButton = () => {
    //TODO: gestione elimina vecchia selezione

    //onInputChange("birthDate",[]);
    // onInputChange("spokenLanguages", []);

    onPrevious();
  };

  const handleSelectChoiceClick = (isClicked, text) => {
    //console.log("isClicked:", isClicked);
    //console.log("text:", text);
    //console.log(spokenLanguages);
    const updatedSpokenLanguages = [...spokenLanguages];
    if (isClicked) {
      // Aggiungiamo l'elemento text alla lista se isClicked è true e l'elemento non è già presente
      if (!updatedSpokenLanguages.includes(text)) {
        updatedSpokenLanguages.push(text);
      }
    } else {
      // Rimuoviamo l'elemento text dalla lista se isClicked è false e l'elemento è presente
      const index = updatedSpokenLanguages.indexOf(text);
      if (index !== -1) {
        updatedSpokenLanguages.splice(index, 1);
      }
    }
    //console.log(updatedWorkModalitiesList)
    onInputChange("spokenLanguages", [...updatedSpokenLanguages]);
  };

  return (
    <div className="onboarding-interno">
      <Logo></Logo>
      <div className="onboarding-screen">
        <div className="typeforms-buttons">
          <div className="title-container">
            <h1 className="title">{title}</h1>
          </div>
          <h2 className="subtitle">{subtitle} </h2>
          <div className="div">
            {/* <Dropdown options={NATIONALITIES} label="Scegli la tua lingua parlata" /> */}
            <div className="selection-element-horrizonatal">
              {stepElevenSpokenLanguage.map((item) => (
                <SelectChoice
                  isClickedProp={spokenLanguages.includes(item)}
                  key={item}
                  text={item}
                  onClick={handleSelectChoiceClick}
                />
              ))}
            </div>
            <ErrorMessageInForm message={errorMessage} />
            <AvantiIndietroStepButtons
              onNext={handleClickNextButton}
              onPrevious={handleClickPreviousButton}
              nextDisabled={
                !isStepValid({
                  mandatoryFields: "spokenLanguages",
                  formData: {
                    spokenLanguages: formData.spokenLanguages,
                  },
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageSpoken;
