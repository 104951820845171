const styles = {
  table: {
    fontFamily: '"Inter", Helvetica',
    width: "100%",
    borderCollapse: "collapse",
    backgroundColor: "var(--white)",
    border: "1px solid rgb(204, 204, 204)",
    borderRadius: "8px",
  },
  header: {
    backgroundColor: "var(--light-blue)",
    color: "var(--black)",
  },
  cell: {
    borderBottom: "1px solid var(--grey)",
    borderRight: "1px solid var(--grey)",
    cursor: "pointer",
    height: "fit-content",
    padding: "12px",
    fontSize: "14px",
  },
  stickyTable: {
    position: "relative",
  },
  stickyTableHeader: {
    position: "sticky",
    top: "-20px",
    backgroundColor: "#fff", // Personalizzabile
    zIndex: 2,
    boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.1)", // Opzionale: aggiunge un'ombra
  },
};

const outerContainerStyle = {
  position: "relative",
};

const scrollbarContainerStyle = {
  // overflowX: "auto",
  height: "16px", // Altezza del div della scrollbar
  borderRadius: "4px",
  border: "1px solid #ccc",
  marginBottom: "10px", // Distanza tra la scrollbar e la tabella
};

const tableContainerStyle = {
  // overflowX: "hidden",
  width: "100%",
  userSelect: "none",
  borderRadius: "8px",
};

const scrollbarStyle = `
  ::-webkit-scrollbar {
    height: 16px;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: #888; 
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;    
    border-radius: 4px;
  }
`;

export {
  styles,
  scrollbarContainerStyle,
  scrollbarStyle,
  outerContainerStyle,
  tableContainerStyle,
};
