import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
} from "firebase/firestore";
import {
  setData,
  setFieldKeys,
  setAvailableFilters,
  setPage,
  setPageTo,
  resetFilters,
} from "../../store/slices/databaseTable";
import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import Menu from "../../components/Menu/index.js";
import { getColumnModelWorkers } from "./model-table/workersModelTable";
import { mockDataWorkers } from "./mockData";
import Table from "../../components/Table/Table";
import Filters from "./model-table/components/Filters/Filters";
import { getFilterFields, getFieldKeys } from "../../utils/jsonUtils";
import Editor from "./model-table/components/Editor";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../lib/firebase/functions";
import { getWorkers } from "../../store/thunks/databaseTable";
import chevronRight from "../../assets/chevron-right.png";
import chevronLeft from "../../assets/chevron-left.png";
import { app } from "../../lib/firebase/config";
import {
  registerUserToSession,
  setSelection,
} from "../../store/slices/vetrina";
import { UserTypes } from "../../types/auth";

const DatabasePageWorkers = () => {
  const [userType, setUserType] = useState();
  const [filtersList, setFiltersList] = React.useState({});

  const [isAuth, setIsAuth] = useState(false);
  const navigate = useNavigate();
  const dispatchRedux = useDispatch();
  const [columnsModelWorkers, setColumnsModelWorkers] = useState([]);
  const [order, setOrder] = useState("desc");

  const currentPage = useSelector((state) => state.databaseTable.currentPage);
  const count = useSelector((state) => state.databaseTable.count);
  const filteredData = useSelector((state) => state.databaseTable.filteredData);
  const isSearching = useSelector((state) => state.databaseTable.isSearching);
  const [orderField, setOrderField] = useState("creation_time");

  const auth = getAuth();

  const primaElemento = (currentPage - 1) * 50 + 1;
  const ultimoElemento =
    count > 50 ? primaElemento + filteredData.length - 1 : count;

  useEffect(() => {
    dispatchRedux(resetFilters());

    return () => {
      dispatchRedux(resetFilters());
    };
  }, []);

  useEffect(() => {
    const auth = getAuth(app);
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        const db = getFirestore(app);
        const docRef = collection(db, "internal_users");
        const q = query(docRef, where("email", "==", user.email));
        const querySnapshot = await getDocs(q);
        setUserType(querySnapshot.docs[0].data().user_type);
        if (querySnapshot.docs[0] && querySnapshot.docs?.length === 0) {
          navigate(
            `/sign-in-admin?from=${encodeURIComponent(
              window.location.pathname
            )}`
          );
        } else {
          dispatchRedux(registerUserToSession(querySnapshot.docs[0]?.data()));
          dispatchRedux(
            setSelection(querySnapshot.docs[0]?.data()?.selection ?? [])
          );
        }
      } else {
        navigate(
          `/sign-in-admin?from=${encodeURIComponent(window.location.pathname)}`
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuth(true);
      } else {
        window.location.pathname = "/sign-in-admin";
      }
    });

    return () => unsubscribe();
  }, [auth]);

  const handlePagination = (direction) => {
    if (direction === "left") {
      dispatchRedux(setPage(direction));
    } else if (direction === "right") {
      dispatchRedux(setPage(direction));
    }
  };

  useEffect(() => {
    const fetchColumns = async () => {
      const columns = await getColumnModelWorkers();
      setColumnsModelWorkers(columns);
    };
    fetchColumns();
  }, []);

  useEffect(() => {
    let filters = {};
    getFilterFields("workers").then((res) => {
      for (const [key, value] of Object.entries(res)) {
        if (value.hasOwnProperty("choices")) {
          filters = { ...filters, [key]: value };
          setFiltersList((p) => ({ ...p, [key]: value }));
        } else {
          for (const nValue of Object.values(value)) {
            if (nValue.hasOwnProperty("choices")) {
              filters = { ...filters, [nValue.key.replace(".", "_")]: nValue };
              setFiltersList((p) => ({
                ...p,
                [nValue.key.replace(".", "_")]: nValue,
              }));
            }
          }
        }
      }
      dispatchRedux(setAvailableFilters(filters));
    });
    getFieldKeys("workers").then((res) => dispatchRedux(setFieldKeys(res)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatchRedux(getWorkers({ currentPage, order, orderField }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, order, orderField]);

  if (isAuth) {
    return (
      <>
        <div className="container">
          <Menu initialItemSelected="Database"></Menu>
          <div className="content">
            <div className="table-admin-button-container">
              <div className="table-admin-button-container__wrapper">
                {userType === UserTypes.ADMIN ? (
                  <button
                    className="table-admin-flex-button isNotSelected"
                    onClick={() => {
                      dispatchRedux(setPageTo(1));
                      navigate("/database-datori-lavoro", { replace: true });
                    }}
                  >
                    💼 Datori di lavoro
                  </button>
                ) : null}
                <button className="table-admin-flex-button isSelected">
                  🙋‍♀️ Lavoratori
                </button>
              </div>
              <div className="table-admin-legenda-right">
                <div className="table-admin-legenda-right__color-circle"></div>
                <div>
                  <p>Cella Verde</p>
                  <p>Dato SEAC</p>
                </div>
              </div>
            </div>
            <Filters />
            <div className="wrapper-table">
              <Table
                columns={columnsModelWorkers}
                setOrder={setOrder}
                order={order}
                setOrderField={setOrderField}
                orderField={orderField}
              />
            </div>
            {!isSearching ? (
              <div className="count-wrapper">
                <img
                  className={`${primaElemento === 1 ? "disabled" : ""}`}
                  onClick={() => handlePagination("left")}
                  src={chevronLeft}
                  alt=""
                />
                <p>
                  {primaElemento} - {ultimoElemento}
                </p>
                <p>/</p>
                <p>{count}</p>
                <img
                  className={`${ultimoElemento === count ? "disabled" : ""}`}
                  onClick={() => handlePagination("right")}
                  src={chevronRight}
                  alt=""
                />
              </div>
            ) : null}
            {/* 
        <iframe
            src="https://bazeapp.retool.com/apps/Ispezione%20DWH"
            title="BazeApp Ispezione DWH"
            width="100%"
            height="100%"
        />
        */}
          </div>
        </div>
        <Editor />
      </>
    );
  } else {
    return <h1>Loading...</h1>;
  }
};

export default DatabasePageWorkers;
