import React, { useState } from "react";
import Button from "../../../components/Button/index";
import "../style.css";
import Logo from "../../../components/Logo/Logo";
import ErrorMessageInForm from "../../../components/ErrorMessageInForm";
import { isAcceptableStepWith2TextForm } from "../functionsSteps";

import { TypeForm } from "../../../components/TypeForm/index";
import { isStepValid } from "../../../utils/onboarding";
import AvantiIndietroStepButtons from "../../../components/AvantiIndietroStepButtons";

const NameAndSurname = ({
  onNext,
  formData,
  title,
  onInputChange,
  onPrevious,
}) => {
  const { name, surname } = formData;
  const [errorMessage, setErrorMessage] = useState("");

  const handleClickNextButton = () => {
    if (isAcceptableStepWith2TextForm(name, surname)) {
      onNext();
    } else {
      setErrorMessage("Inserisci un nome e cognome");
    }
  };

  const handleInputChange = (fieldName, value) => {
    onInputChange(fieldName, value);
  };

  const handleClickPreviousButton = () => {
    // onInputChange("name", "");
    // onInputChange("surname", "");
    // onInputChange("experienceTitle", "");
    // onInputChange("experienceDescription", "");

    onPrevious();
  };

  return (
    <div className="onboarding-interno">
      <Logo></Logo>
      <div className="onboarding-screen">
        <div className="typeforms-buttons">
          <div className="title-container">
            <h1 className="title">{title} </h1>
          </div>
          <h2 className="subtitle"> </h2>
          <div className="div">
            <TypeForm
              className="type-form-instance"
              label="Nome"
              value={name}
              onChange={(value) => handleInputChange("name", value)}
            />
            <TypeForm
              className="type-form-instance"
              label="Cognome"
              value={surname}
              onChange={(value) => handleInputChange("surname", value)}
            />
            <ErrorMessageInForm message={errorMessage} />
            <AvantiIndietroStepButtons
              onNext={handleClickNextButton}
              onPrevious={handleClickPreviousButton}
              nextDisabled={
                !isStepValid({
                  mandatoryFields: ["name", "surname"],
                  formData: {
                    name: formData.name,
                    surname: formData.surname,
                  },
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NameAndSurname;
