import React from "react";
import "./style.css";
import { Warning } from "@phosphor-icons/react";

const ErrorMessageInForm = ({
  headline = "Errore:",
  message = "Paragraph text",
  paragraphIsVisible = true,
  className = "banner-instance",
}) => {
  if (message === "") {
    return <></>;
  } else {
    return (
      <div className={`banner ${className}`}>
        <div className="div container-error-message">
          <p>
            <Warning size={24} weight="bold" color="#E31B23" />
          </p>
          <div className="text-frame">
            <div className="headline-text">{headline}</div>
            {paragraphIsVisible && (
              <div className="paragraph-text">{message}</div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default ErrorMessageInForm;
