import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { IconInterfacesUser } from "../IconInterfacesUser";
import userIcon from "../../img/static/user-icon.svg";
import eyeIcon from "../../img/static/eye-icon.svg";
import hiddenEyeIcon from "../../img/static/hidden-eye-icon.svg";
import "./style.css";

export const TypeForm = ({
  label = "Campo input",
  isPassword = false,
  showIcon = true,
  type = "text",
  className,
  maxLength = 0,
  icon = undefined,
  override = (
    <IconInterfacesUser
      className="icon-interfaces-user-instance"
      combinedShape={icon ?? userIcon}
    />
  ),
  value = "",
  onChange,
  prefix = undefined,
  ...props
}) => {
  const inputRef = useRef(null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [currentValue, setCurrentValue] = useState("");

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setCurrentValue(newValue);

    onChange(newValue);
  };

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleIconClick = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const displayValue = value !== "" ? value : label;

  return (
    <div className={`type-form ${className}`}>
      {showIcon && (
        <div className="icon">
          {override}
          <div className="divider" />
        </div>
      )}
      {prefix && prefix}
      <input
        maxLength={maxLength === 0 ? 1000000 : maxLength}
        type={isPassword && !isPasswordVisible ? "password" : type}
        className="campo-input"
        placeholder={label ?? ""}
        ref={inputRef}
        value={currentValue}
        onChange={handleInputChange}
        {...props}
      />
      {maxLength !== 0 ? (
        <div className="text-limit-wrapper">
          <p>
            {value.length}/{maxLength}
          </p>
        </div>
      ) : null}
      {isPassword && (
        <div className="icon" onClick={handleIconClick}>
          <div className="divider" />
          {isPasswordVisible ? (
            <img src={eyeIcon} className="icon-interfaces-show-password" />
          ) : (
            <img
              src={hiddenEyeIcon}
              className="icon-interfaces-show-password"
            />
          )}
        </div>
      )}
    </div>
  );
};

TypeForm.propTypes = {
  label: PropTypes.string,
  showIcon: PropTypes.bool,
  className: PropTypes.string,
  override: PropTypes.node,
  onChange: PropTypes.func,
};

export default TypeForm;
